@import '../../../styles/variables.scss';

.phraseBlock {

	&Opacity {
		opacity: 0.5;
	}

	&WithButtons {
		margin-block: 2px 4px;
		padding: 3px 13px 8px;
		border: $borderSection;
		border-radius: 4px;

		&:hover {
			border: 1px solid #000;
		}

		&Focus {
			border: 2px solid $colorSecondary !important;
			padding: 2px 12px 7px;

			&>legend {
				color: $colorSecondary;
			}
		}

		&Active {
			border: 1px solid $colorSecondary;
		}

		&>legend {
			margin-left: -5px;
			padding-inline: 6px;
			font-size: 10px;
		}
	}
}

.buttonBlock {
	margin-top: 8px;
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
}

.button {
	flex: 100px 1 0;
	padding: 3px 6px;
	background-color: transparent;
	border: $borderSection;
	border-radius: 4px;
	outline: none;
	word-break: break-word;
	color: inherit;
	transition: 0.2s;

	&:hover {
		border: 1px solid $colorSecondary;
		box-shadow: 0 0 5px $colorSecondaryLight;
		color: $colorSecondary;
		cursor: pointer;
	}
}

.contextMenuItem {
	display: flex;
	gap: 8px;
	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 8px;
	}

	&:hover {
		color: $colorSecondary;
	}
}