@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.configRobot {
	position: absolute;
	top: 0;
	right: 0;
	padding: 16px 0;
	width: 400px;
	max-width: 90%;
	height: 100%;
	border-left: $borderSection;
	background: $backgroundColor;

	&Wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		text-align: left;
		overflow: auto;
	}

	&Top {
		flex-grow: 1;
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		gap: 8px;
		overflow: auto;

		&NotFoundTitle {
			text-align: center;
		}
	}

	&Bottom {
		margin: 0 16px;
		display: flex;
		gap: 16px;
	}
}

.loading,
.failed {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.tagClose {
	position: absolute;
	left: -50px;
	bottom: 85px;
	padding: 4px 8px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}