@import '../../styles/variables.scss';

.infoBlock {
	padding-block: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	box-shadow: 0 0 4px $gray-200;
	background-color: $backgroundColorLightening;

	&Inner {
		padding-inline: 16px;
		height: 100%;
		overflow: auto;
	}
}