@import '../../styles/variables.scss';

.infoTop,
.infoClasses {
	width: 240px;
	padding: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	box-shadow: 0 0 16px $shadowColorBlockInfo;
	text-align: left;
}

.info {
	min-height: 480px;
	display: flex;
	flex-direction: column;

	&Top {
		display: flex;
		flex-direction: column;

		&TitleBlock {
			margin-bottom: 8px;
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			color: $colorSecondary;
		}

		&DescBlock {
			word-wrap: break-word;
		}

		&Title {
			max-width: 176px;
			word-wrap: break-word;
		}

		&TitleForm {
			display: flex;
			gap: 4px;
		}

		&TitleFunc {
			flex-shrink: 0;
			display: flex;
			align-items: center;
			gap: 8px;
		}
	}

	&Classes {
		margin-top: 16px;
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;
		overflow: auto;

		&Title {
			color: $colorSecondary;
			font-size: 12px;
			word-wrap: break-word;
			text-align: center;
		}

		&List {
			margin: 0 -16px;
			padding: 0 16px;
			flex-grow: 1;
			overflow: auto;
		}

		&Item {
			display: flex;
			justify-content: space-between;
			gap: 4px;

			&Key {
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				cursor: pointer;
			}

			&Edit {
				display: none;
			}

			&:not(:last-child) {
				border-bottom: 1px solid $shadowColor;
			}

			&:hover &Edit {
				display: flex;
				align-items: center;
				gap: 4px;
			}

			&:hover &Value {
				display: none;
			}

			&:hover {
				margin: 0 -16px;
				padding: 0 16px;
				background-color: $backgroundColorBtn;
				color: $colorSecondary;
			}
		}

		&NoData {
			margin-top: 16px;
			text-align: center;
		}

		&Export {
			align-self: center;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&Key {
		font-weight: bold;
	}
}

.renameForm {
	width: 100%;
	display: flex;
	gap: 3px;

	&Input {
		width: 100%;
		padding: 0 4px;
		outline: none;
		font-size: inherit;
		color: $colorPrimary;
	}

	&Btn {
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: inherit;
	}
}