@import '../../styles/variables.scss';

.container {
	width: 100%;
	min-height: 40px;
	display: flex;
	align-items: center;
	gap: 16px;
	position: relative;

	&SmallSize {
		min-height: 20px;
	}
}

.playBtn {
	min-width: 32px;
	width: 32px;
	height: 32px;
	border: none;
	border-radius: 50%;
	outline: none;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $colorSecondary;
	cursor: pointer;

	&SmallSize {
		min-width: 20px;
		width: 20px;
		height: 20px;
	}

	&NotActive {
		background-color: $colorPrimary;
		cursor: not-allowed;
	}

	&Square {
		border-radius: 8px;
	}
}

.audioPlayerWrapper {
	flex-grow: 1;

	&NoData {
		border: 1px solid $colorPrimary;
	}

	&PercentageLoading {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.timeline {
	position: absolute;
	right: 40px;
}

.download {
	transition: 0.3s;
	will-change: transform;

	&:hover {
		transform: scale(1.1);
	}

	&NotActive {
		cursor: not-allowed;
	}
}