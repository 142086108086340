@import '../../../styles/variables.scss';

.aliasBlock {
	display: flex;
	gap: 8px;
	padding: 2px 0;
	position: relative;

	// &:hover .aliasBlockButtons {
	// 	opacity: 1;
	// }

	&:not(:last-child) {
		margin-bottom: 4px;
	}

	&:hover .textareaBlockButtons {
		opacity: 1;
	}

	&Checkbox {
		accent-color: $colorSecondary;
		cursor: pointer;
	}

	// &Buttons {
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	gap: 8px;
	// 	opacity: 0;
	// 	transition: 0.1s;
	// }
}

.textarea {
	padding: 8px 40px 8px 8px;
	width: 100%;
	border: $borderSection;
	border-radius: 4px;
	outline: none;
	resize: none;
	background-color: inherit;
	font-family: inherit;
	font-size: 12px;
	color: inherit;

	&:hover {
		border: 1px solid #000;
	}

	&:focus {
		padding: 7px 40px 7px 7px;
		border: 2px solid $colorSecondary;
	}

	&BackgroundLighting {
		background-color: $colorSecondaryLight3;
	}

	&BlockButtons {
		position: absolute;
		top: 50%;
		right: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		opacity: 0;
		transition: 0.1s;
		transform: translateY(-50%);
	}
}