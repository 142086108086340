@import '../../styles/variables.scss';

.blockTag {
	&Wrapper {
		position: absolute;
		bottom: -21px;
		width: 100%;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 0.3s;
		opacity: 0;
		z-index: 1;

		&:hover {
			transition-delay: 0.1s;
			opacity: 1;
		}

		&Full {
			top: -21px;
		}
	}

	&Inner {
		padding-bottom: 15px;
		width: 40px;
		height: 40px;
		border-top: $borderSection;
		border-radius: 50%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		background: linear-gradient(0deg, transparent 0%,
				transparent 50%,
				$backgroundColorLightening 51%,
				$backgroundColorLightening 100%);
		cursor: pointer;

		&Full {
			padding-bottom: initial;
			padding-top: 15px;
			border-top: initial;
			border-bottom: $borderSection;
			background: linear-gradient(180deg,
					transparent 0%,
					transparent 50%,
					$backgroundColorLightening 51%,
					$backgroundColorLightening 100%);
		}
	}
}