@import '../../styles/variables.scss';

.checkResult {
	height: 100%;
	display: flex;
	flex-direction: column;

	&EditorWrapper {
		padding: 16px;
		overflow: auto;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	text-align: left;
}

.failedText,
.notFoundText,
.loading {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}