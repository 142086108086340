@import '../../../styles/variables.scss';

.sidebarTag {
	&Wrapper {
		position: absolute;
		right: -20px;
		width: 40px;
		height: 100%;
		display: flex;
		align-items: center;
		transition: 0.3s;
		opacity: 0;
		z-index: 0;

		&:hover {
			transition-delay: 0.1s;
			opacity: 1;
		}
	}

	&Inner {
		padding-left: 15px;
		width: 40px;
		height: 40px;
		border-right: $borderSection;
		border-radius: 50%;
		display: flex;
		justify-content: space-around;
		align-items: center;
		background: linear-gradient(90deg,
				transparent 0%,
				transparent 50%,
				$backgroundColorLightening 51%,
				$backgroundColorLightening 100%);
		cursor: pointer;
	}
}