.container {
	padding: 24px;
	max-height: 95vh;
	text-align: left;
	overflow: auto;
}

.header {
	margin-bottom: 10px;
	text-align: center;
}