@import '../../../styles/variables.scss';

.jsonBlock {
	position: absolute;
	left: -10px;
	padding: 8px 0;
	width: 500px;
	height: 80vh;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
	background-color: $backgroundColor;
	box-shadow: 0 2px 10px 1px #b5b5b5;
	text-align: left;
	transform: translateX(-100%);

	&PositionRight {
		left: initial;
		right: -10px;
		transform: translateX(100%);
	}

	&Inner {
		flex-grow: 1;
		padding: 0 8px;
		display: flex;
		overflow: auto;
	}

	&Empty {
		width: 100%;
		align-self: center;
		text-align: center;
		font-size: 12px;
	}
}