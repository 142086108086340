@import '../../styles/variables.scss';

.bottomContainer {
	position: absolute;
	top: 56px;
	display: flex;
	width: 100%;

	@media (max-width: 1024px) {
		width: 1024px;
	}
}

.wrapper {
	width: 100%;
	height: calc(100vh - 56px);
	padding: 16px;
	box-shadow: inset -1px -1px 6px $shadowColor;
	display: flex;
	gap: 16px;

	transition: 800ms cubic-bezier(0, 0, 0.2, 1) 0ms;
	position: relative;
	animation-name: opacityIn;
	animation-delay: 500ms;
	animation-duration: 700ms;
	animation-fill-mode: backwards;

	@keyframes opacityIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	&FullWidth {
		transition: 800ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
		margin-left: -400px;
	}

	&Question {
		flex-shrink: 0;
		max-width: 50%;
		width: 360px;
		padding: 16px;
		border: $borderBlockInfo;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
		box-shadow: 0 0 16px $shadowColorBlockInfo;
		overflow: auto;
	}
}

.noData {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	&>div {
		padding: 10px 14px;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
	}
}