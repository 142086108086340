@import '../../styles/variables.scss';

.container {
	margin: 0 -16px;
	flex-grow: 1;
	overflow: auto;
}

.contextMenu {
	&Container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 20;
	}

	&Wrapper {
		position: absolute;
		min-width: 150px;
		max-width: 300px;
		border-radius: 6px;
		box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
		background: $backgroundColor;
		overflow: hidden;
	}

	&Title {
		padding: 8px 16px;
		border-bottom: $borderBlockInfo;
		text-align: center;
		color: $colorPrimary;
		background: $backgroundColorBtn;
	}

	&List {
		max-height: 200px;
		overflow: auto;
	}

	&Option {
		padding: 8px 16px;
		color: $colorSecondary;
		cursor: pointer;

		&:hover {
			background-color: $colorSecondaryLight3;
		}

		&:not(:last-child) {
			border-bottom: $borderBlockInfo;
		}

		&NotActive {
			padding: 8px 16px;
			color: $colorPrimary;
		}
	}
}