@import '../../../styles/variables.scss';

.block,
.switchBlock {
	display: flex;
	align-items: center;
}

.block {
	gap: 16px;

	&Info,
	&InfoError {
		font-size: 12px;
		margin-block: 4px 2px;
	}

	&InfoError {
		color: $red;
	}
}