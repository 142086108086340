@import '../../../styles/variables.scss';

.tag {
	position: relative;
	right: 0;
	width: 40px;
	height: 80px;
	padding-right: 12px;
	border: $borderSection;
	border-radius: 4px 0 0 4px;
	box-shadow: 0 0 6px $shadowColor;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $backgroundColorBtn;
	cursor: pointer;
	transition: 0.3s;
	overflow: hidden;

	&:hover {
		right: 9px;
	}

	&Title {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 6px;
		transform: rotate(-90deg);

		&SmallSize {
			font-size: 10px;
		}
	}
}

.serverStatus {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: $red;
}

.serverStatusSuccess {
	background: $green;
}