@import '../../styles/variables.scss';

.containerInLine {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.containerToColumn {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.description {
	color: $colorSecondary;
}