@import '../../styles/variables.scss';

.dockPanel {
	padding: 16px;
	display: flex;
	align-items: center;
	gap: 16px;
	border-radius: 18px;
	background-color: rgba(255, 255, 255, 0.7);
	color: #000;
	position: relative;

	&Link {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 13px;
		transition: 0.3s;
		will-change: transform;
		cursor: pointer;

		&:hover {
			transform: scale(1.2);

			.dockPanelTitle {
				display: block;
			}
		}

		&NonActive {
			cursor: not-allowed;

			// &:hover {
			// 	transform: none;
			// }

			&>img {
				opacity: 0.8;
				filter: grayscale(1);
			}
		}

		&Active {
			cursor: default;

			// &:hover {
			// 	transform: none;
			// }

			&>img {
				box-shadow: 0 0 8px red;
			}
		}
	}

	&Title {
		position: absolute;
		top: -30px;
		left: 50%;
		padding: 3px 6px;
		display: none;
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.7);
		transform: translateX(-50%);
		transition: 0.3s;
		white-space: pre;
	}

	&Img {
		width: 80px;
		border-radius: 18px;
	}

	&LogoutIcon {
		width: 80px;
		height: 80px;
		border-radius: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $red;
	}
}