.pageNotFound {
	margin-top: 5%;
}

.header {
	margin-bottom: 20px;
}

.linkToHome {
	text-decoration: underline;
}