@import '../../styles/variables.scss';

.header {
	margin-bottom: 8px;
	font-weight: normal;
	color: $colorSecondary;
}

.data {

	&>div,
	&>p {
		margin-top: 8px;
		padding-top: 8px;
		border-top: $borderSection;
	}

	&Answer {
		text-align: left;
		white-space: pre-wrap;
		word-wrap: break-word;
	}

	&Confidence {
		margin-bottom: 10px;
		text-align: left;

		&Title {
			font-weight: bold;
		}
	}
}