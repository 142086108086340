@import '../../styles/variables.scss';

.wrapper {
	height: calc(100vh - 56px);
	margin-top: 56px;
	margin-left: 240px;
	padding: 16px;
	display: flex;
	gap: 16px;
	box-shadow: inset -1px -1px 6px $shadowColor;
	overflow: hidden;
	position: relative;
}

.noData {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&>div {
		padding: 10px 14px;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
	}
}

.chatWidgetBtn {
	position: fixed;
	bottom: 30px;
	right: 40px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $colorSecondary;
	transition: 0.3s;
	will-change: scale;
	cursor: pointer;
	z-index: 10;

	&:hover {
		scale: 1.05;
	}
}