@import '../../styles/variables.scss';

.dockPanel {
	position: absolute;
	left: 50%;
	bottom: 0;
	padding: 6px 10px;
	transform: translateX(-50%);
	transition: 0.3s;
	z-index: 30;

	&Hover:hover {
		padding-bottom: 10px;
		cursor: pointer;
	}

	&Initiator {
		margin: 0 auto;
		width: 150px;
		height: 4px;
		border-radius: 4px;
		background-color: $gray-200;
		box-shadow: 0 0 4px #fff;
		transition-delay: 0.4s;
		transition-duration: 0.3s;

		&Opacity {
			opacity: 0;
		}
	}
}