@import '../../../styles/variables.scss';

.infoClassesItem {
	margin: 0 16px;
	display: flex;
	justify-content: space-between;
	gap: 4px;

	&Key {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&Name {
		margin-left: 4px;
		cursor: pointer;
	}

	&Edit {
		display: none;
	}

	&:hover &Edit {
		display: flex;
		align-items: center;
		gap: 4px;
	}

	&:hover &Value {
		display: none;
	}

	&:hover {
		background-color: $backgroundColorRow;
		color: $colorSecondary;
	}
}

.renameForm {
	width: 100%;
	display: flex;
	align-items: center;
	gap: 3px;

	&Warn {
		position: absolute;
		top: 17px;
		right: 0;
		padding: 2px 4px;
		background-color: $backgroundColor;
		z-index: 10;
		border: 1px solid $red;
		border-radius: 4px;
		text-align: center;
		box-shadow: 0 2px 3px $red;
		color: $colorSecondary;
	}

	&Input {
		width: 100%;
		padding: 0 4px;
		outline: none;
		font-size: inherit;
		color: $colorPrimary;

		&Warn {
			border: 1px solid $red;
			box-shadow: 0 0 1px $red;
		}
	}

	&Btn {
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: inherit;
	}
}