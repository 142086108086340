@import '../../styles/variables.scss';

.matrix-wrap {
  position: relative;
  height: 100%;
  // border: 1px solid #dfdfdf;
  // box-shadow: 0 0 20px #dfdfdf;
  // border-radius: 7px;
  // height: 500px;
  // min-height: calc(100vh - 520px);
  // max-height: calc(100vh - 160px);
  // width: 600px;
  // min-width: 750px;
  // min-width: 100%;
  // max-width: calc(100vw - 800px);
  // width: 100%;
  // flex-grow: 2;
  // flex-shrink: 0;
}

.gradusnik {
  position: absolute;
  top: -8px;
  right: 0;
  width: 48px;
  height: 100%;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 8px;
    bottom: 24px;
    width: 10px;
    background: linear-gradient(to top, #fff, $colorSecondaryLight2 10%, $colorSecondaryLight 90%, $colorSecondary);
    right: 16px;
    border: 1px solid $gray-200;
  }

  .graduscale {
    list-style: none;
    padding: 0;
    width: 26px;
    line-height: 16px;
    height: calc(100% - 34px);

    >li {
      position: relative;
      padding: 0 8px 0 0;
      margin: 0;
      height: 20%;
      vertical-align: middle;
      text-align: right;

      &:first-child {
        padding-top: 8px;
      }

      &:last-child {
        margin-top: -8px;

        // черточки
        // &:after {
        //   content: '';
        //   display: block;
        //   width: 8px;
        //   height: 1px;
        //   background: $gray-200;
        //   position: absolute;
        //   top: 21px;
        //   right: 1px;
        // }
      }

      // черточки
      // &:after {
      //   content: '';
      //   display: block;
      //   width: 8px;
      //   height: 1px;
      //   background: $gray-200;
      //   position: absolute;
      //   top: 10px;
      //   right: 1px;
      // }
    }
  }
}

.matrix-bg {
  width: 100%;
  background: lighten($light-primary, 2%);
  padding: 0;
  position: relative;

  .horbar {
    display: none;
    // height: 1px;
    opacity: 0.3;
    position: absolute;
    z-index: 20;
    left: 180px;
    // left: 182px;
    // background: $colorSecondary;
    box-shadow: -1px -1px 1px 1px $colorBorderHoverBar;
    // box-shadow: 0 0 10px rgba(2, 87, 154, 0.5);

    &:before {
      content: '';
      display: block;
      height: 26px;
      // width: 1px;
      position: absolute;
      top: 0;
      left: 0;
      // background: $colorSecondary;
      box-shadow: -1px 0 1px 1px $colorBorderHoverBar;
      // box-shadow: 0 0 10px rgba(2, 87, 154, 0.5);
    }

    &:after {
      content: '';
      display: block;
      // height: 1px;
      position: absolute;
      top: 26px;
      left: 0;
      right: 0;
      // background: $colorSecondary;
      box-shadow: -1px 1px 1px 1px $colorBorderHoverBar;
      // box-shadow: 0 0 10px rgba(2, 87, 154, 0.5);
    }
  }

  .verbar {
    display: none;
    // width: 1px;
    opacity: 0.3;
    position: absolute;
    z-index: 20;
    // background: $colorSecondary;
    box-shadow: -1px 1px 1px 1px $colorBorderHoverBar;
    // box-shadow: 0 0 2px rgba(2, 87, 154, 0.5);

    &:before {
      content: '';
      display: block;
      width: 26px;
      // height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
      // background: $colorSecondary;
      box-shadow: 0 1px 1px 1px $colorBorderHoverBar;
      // box-shadow: 0 0 2px rgba(2, 87, 154, 0.5);
    }

    &:after {
      content: '';
      display: block;
      // width: 1px;
      position: absolute;
      left: 26px;
      top: 0;
      bottom: 0;
      // background: $colorSecondary;
      box-shadow: 1px 1px 1px 1px $colorBorderHoverBar;
      // box-shadow: 0 0 2px rgba(2, 87, 154, 0.5);
    }
  }
}

.notFoundMatrix {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.matrix-table {
  margin: 0;
  padding: 0;
  border: 0px none;
  table-layout: fixed;
  // border-collapse: collapse;
  // border: 1px solid #000;

  td {
    width: 26px;
    height: 26px;
    max-width: 26px;
    min-width: 26px;
    max-height: 26px;
    min-height: 26px;
    line-height: 16px;
    padding: 0;
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    font-size: 10px;

    div {
      width: 25px;
      height: 25px;
      padding: 4px 0;
      text-align: center;
    }
  }

  th {
    // width: 32px;
    // height: 32px;
    line-height: 16px;
    vertical-align: middle;
    text-align: right;
    font-weight: normal;
    // border: 1px solid #000;
  }

  th.active {
    color: $colorSecondary;
  }

  tbody th {
    // min-width: 115px;
    // width: 100px;
    padding-left: 10px;
    padding-right: 10px;

    div {
      // min-width: 80px;
      width: 160px;
      height: 24px;
      padding: 4px 0;
      vertical-align: middle;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  tfoot th {
    vertical-align: top;
    padding: 8px 0 0 0;
    height: 88px;

    div {
      height: 80px;
      width: 26px;
      overflow: hidden;
      display: block;

      >span {
        display: block;
        width: 72px;
        height: 72px;
        line-height: 1;
        //text-overflow: ellipsis;

        transform: rotate(-90deg);
        transform-origin: 50% 50%;
        text-align: right;
        // vertical-align: middle;

        >span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          display: block;
          width: 72px;
        }
      }
    }
  }
}

.matrix-popup {
  position: fixed;
  opacity: 0;
  // width: 270px;
  min-height: 40px;
  padding: 8px;
  background: #FFFFFF;
  border: 1px solid #E7E7E7;
  box-shadow: 0px 5px 20px rgba(128, 130, 136, 0.25);
  border-radius: 2px;
  z-index: 500;
  transition: opacity 0.3s ease-out, left 0.3s linear, top 0.3s linear;

  &>div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  // .the-after {
  //   display: block;
  //   content: '';
  //   height: 36px;
  //   width: 1px;
  //   background: $gray-200;
  //   top: 17px;
  //   right: 63px;
  //   position: absolute;
  // }

  // table {
  //   width: 268px;
  //   height: 68px;
  //   table-layout: fixed;
  //   border: 1px solid #000;

  //   td {
  //     height: 34px;
  //     padding-left: 16px;
  //     padding-right: 16px;
  //     border: 0px none;
  //     vertical-align: middle;
  //     text-align: left;
  //     overflow: hidden;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;
  //     line-height: 24px;
  //   }

  //   td.mp-verb1 {
  //     width: 206px;
  //     color: #000;
  //     padding-top: 12px;
  //     padding-bottom: 0;
  //   }

  //   td.cnt {
  //     width: 62px;
  //     color: #000;
  //     padding-top: 12px;
  //     padding-bottom: 0;
  //     padding-right: 4px;
  //     padding-left: 4px;
  //     text-overflow: none;
  //     text-align: center;
  //   }

  //   td.mp-verb2 {
  //     width: 206px;
  //     color: $gray-500;
  //     padding-top: 0;
  //     padding-bottom: 12px;
  //   }

  //   td.pcnt {
  //     width: 62px;
  //     color: $gray-500;
  //     padding-right: 4px;
  //     padding-left: 4px;
  //     padding-top: 0;
  //     padding-bottom: 12px;
  //     text-overflow: none;
  //     text-align: center;
  //   }
  // }
}