@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.categories {
	position: absolute;
	top: 0;
	right: 0;
	width: 340px;
	height: 100%;
	padding: 16px;
	border-left: $borderSection;
	background: $backgroundColor;

	&Inner {
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		position: relative;
	}

	&Header {
		font-weight: normal;
		color: $colorSecondary;
	}

	&List {
		margin: 0 -16px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 10px;
		font-size: 12px;
		overflow: auto;

		&>ul {
			padding: 0 16px;
			overflow: auto;
		}
	}

	&Item {
		padding: 2px;
		display: flex;
		justify-content: space-between;
		gap: 4px;
		border-radius: 6px;

		&:hover {
			background-color: $backgroundColorRow;
			color: $colorSecondary;
		}

		&:hover &Edit {
			display: flex;
			align-items: center;
			gap: 8px;
		}

		&Name {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		&Edit {
			display: none;
		}
	}

	&Btns {
		padding: 0 16px;
		display: flex;
		gap: 8px;

		&Export {
			flex-grow: 1;
			overflow: hidden;
		}
	}
}

.tagClose {
	position: absolute;
	right: 310px;
	bottom: 85px;
	padding: 4px 8px 5px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}

.renameForm {
	width: 100%;
	display: flex;
	gap: 3px;
	position: relative;

	&Input {
		width: 100%;
		padding: 0 4px;
		outline: none;
		font-size: inherit;
		color: $colorPrimary;

		&Warn {
			border: 1px solid $red;
			box-shadow: 0 0 5px $red;
		}
	}

	&Btn {
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: inherit;
	}
}

.loading,
.failed,
.empty {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}