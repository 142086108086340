@import '../../../../styles/variables.scss';

.footer {
	padding: 8px;
	border-top: 1px solid rgba(224, 224, 224, 1);

	&BoxShadow {
		box-shadow: 0 -5px 10px 0 $backgroundColor;
	}
}

.pagination {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}