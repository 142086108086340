@import '../../styles/variables.scss';

.role {
	height: 100%;
	padding: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	text-align: left;
	overflow: auto;

	&FailedRights {
		margin-bottom: 16px;
		font-size: 13px;
		color: $red;
	}

	&ListByType {
		padding: 8px;
		border: $borderBlockInfo;
		border-radius: 6px;

		&:not(:last-child) {
			margin-bottom: 16px;
		}
	}

	&List {
		margin-top: 5px;
		columns: 3;

		@media (min-width: 1280px) {
			columns: 4;
		}
	}
}