@import '../../styles/variables.scss';

.verify {
	height: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	box-shadow: 0 0 16px $shadowColorBlockInfo;
	overflow: auto;

	&Inner {
		position: relative;
		width: 300px;
	}

	&Result {
		position: absolute;
		width: 100%;
		text-align: center;

		&>div,
		&>p {
			margin: 16px auto;
			padding-top: 16px;
			border-top: $borderSection;
			text-align: center;
		}

		&Pair {
			text-align: left;
		}

		&Key {
			font-weight: bold;
		}
	}
}

.form {

	&Microphone {
		margin: 0 auto 16px;
		width: 100px;
		height: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 20px;
		background-color: $gray-500;
		cursor: pointer;
		transition: 0.3s;
		will-change: transform;

		&:hover {
			background-color: $colorSecondary;
		}

		&:active {
			box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, .2);
			transform: scale(0.95);
		}

		&NotAllow {
			cursor: not-allowed;

			&:hover {
				background-color: $gray-500;
			}
		}
	}

	&File {
		&Name {
			margin: 16px 0 5px;
		}

		&Input {
			position: absolute;
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			z-index: -1;
		}

		&Label {
			margin: 5px 0 16px;
			display: block;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}