@import '../../styles/variables.scss';

.login {
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-position: center;
	background-size: cover;
	overflow: auto;

	&Form {
		padding: 16px;
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.7);
		position: relative;
	}

	&Error {
		position: absolute;
		bottom: -28px;
		padding: 6px 12px;
		border-radius: 12px;
		background-color: rgba(255, 255, 255, 0.7);
		font-size: 12px;
		color: $red;
	}
}