@import '../../../styles/variables.scss';

// .copyRightsTitle {
// 	margin: 5px 0;
// 	font-size: 13px;
// 	text-decoration: underline;
// 	cursor: pointer;

// 	&:hover {
// 		color: $colorSecondary;
// 	}
// }

.rightGroups {
	position: relative;
}

.rightGroup {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
}

.failedText {
	margin: 10px 0;
	font-size: 13px;
	color: $red;
}

// .loading {
// 	position: absolute;
// 	margin-left: -15px;
// 	margin-top: -45px;
// 	width: calc(100% + 30px);
// 	height: calc(100% + 90px);
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	border-radius: 4px;
// 	background-color: $backgroundColor;
// 	opacity: 0.8;
// 	z-index: 20;
// }