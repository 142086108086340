@import '../../../styles/variables.scss';

.form {
	display: flex;
	align-items: center;
	gap: 8px;

	&Button,
	&LabelFileIcon {
		min-width: 32px;
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 8px;
		background-color: $colorSecondary;
		cursor: pointer;
		transition: 0.2s;
		will-change: transform;

		&:disabled {
			background-color: $colorSecondaryLight;
			cursor: not-allowed;
		}

		&:not(:disabled):active {
			box-shadow: inset 0 0 5px 5px rgba(144, 62, 62, 0.2);
			transform: scale(0.95);
		}
	}

	&InputFile {
		position: absolute;
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		z-index: -1;
	}

	&LabelFile {

		&Icon {
			background-color: $colorSecondary;

			&NotAllow {
				background-color: $colorSecondaryLight;
				cursor: not-allowed;

				&:hover {
					background-color: $colorSecondaryLight !important;
				}

				&:active {
					box-shadow: none !important;
					transform: none !important;
				}
			}
		}
	}

	&AudioWrapper {
		margin-inline: 10px;
		width: 200px;
		text-align: center;
		// white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&Setting {
		display: flex;
		flex-direction: column;
		padding: 16px;
		color: $colorPrimary;

		&Model {
			display: flex;
			justify-content: space-between;
			gap: 8px;

			&Block {
				display: flex;
				gap: 8px;

				// кнопки
				&>button {
					width: 32px;
					height: 32px;
					border-radius: 8px;
					border: none;
					outline: none;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: $colorSecondary;
					transition: 0.3s;
					cursor: pointer;
					will-change: transform;

					&:not(:disabled):hover {
						transform: scale(1.1);
					}

					&:disabled {
						background-color: $colorSecondaryLight;
						cursor: not-allowed;
					}
				}
			}
		}
	}
}