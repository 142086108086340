@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
	opacity: 1;
}

.codeEditorWrapper {
	position: absolute;
	top: 0;
	right: 0;
	min-width: calc((100vw - 240px) - (100vw - 240px)/3);
	height: 100%;
	border-left: $borderSection;
	background: $backgroundColor;

	@media (max-width: 1024px) {
		min-width: calc(100vw - 300px);
		max-width: calc(100vw - 50px);
	}
}

.codeEditor {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;

	&Buttons {
		width: 100%;
		padding: 0 16px 16px;
		display: flex;
		justify-content: flex-end;
		gap: 8px;
		overflow: auto;
	}
}

.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.tagClose {
	position: absolute;
	bottom: 85px;
	left: -50px;
	width: 80px;
	padding: 4px 8px 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: #dddddd;
	transform: rotate(-90deg);
	cursor: pointer;

	&Loading {
		background-color: $backgroundColor;
	}
}