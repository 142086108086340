@import '../../../styles/variables.scss';

.form {
	flex-basis: 150px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	overflow: auto;
	position: relative;
	transition: 0.3s;

	&FullHeight {
		flex-basis: 100%;
		flex-grow: 1;
	}
}

.topBlock {
	flex-grow: 1;
	position: relative;

	&:hover .inputFileBlock>label {
		opacity: 0.5;
	}

	&Textarea {
		width: 100%;
		height: 100%;
		padding: 10px;
		border: $borderSection;
		border-radius: 4px;
		outline: none;
		resize: none;
		background-color: inherit;
		color: inherit;
		font-size: inherit;
		font-family: inherit;

		&:hover {
			border: 1px solid #000;
		}

		&:focus {
			padding: 9px;
			border: 2px solid $colorSecondary;
		}

		&::placeholder {
			opacity: 0.5;
		}
	}
}

.inputFileBlock {
	position: absolute;
	bottom: 2px;
	right: 2px;
	border-radius: 4px;
	transition: 0.2s;

	&:hover {
		background-color: $backgroundColor;
	}

	&>input {
		position: absolute;
		width: 0.1px;
		height: 0.1px;
		opacity: 0;
		overflow: hidden;
		z-index: -1;
	}

	&>label {
		padding: 5px 10px;
		display: block;
		cursor: pointer;
		opacity: 0;
	}
}

.bottomBlock {
	display: flex;
	gap: 16px;
}