@import '../../styles/variables.scss';

.wrapper {
	height: calc(100vh - 56px);
	margin-top: 56px;
	margin-left: 240px;
	padding: 16px;
	display: flex;
	gap: 16px;
	box-shadow: inset -1px -1px 6px $shadowColor;
	overflow: auto;
	position: relative;

	&DirectionColumn {
		flex-direction: column-reverse;
	}

	&Left,
	&Right {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&Left {
		flex-grow: 1;
	}

	&Right {
		flex-shrink: 0;
		max-width: 50%;
	}

	&AudioTrack,
	&ResultRecognition,
	&Info,
	&Speakers {
		border: $borderBlockInfo;
		background-color: $backgroundColorLightening;
		box-shadow: 0 0 16px $shadowColorBlockInfo;
		border-radius: 6px;
		overflow: auto;
	}

	&AudioTrack,
	&Info {
		flex-shrink: 0;
		padding: 16px;
		min-height: 80px;

		&>div {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	&ResultRecognition,
	&Speakers {
		flex-grow: 1;
	}
}

// .loading,
// .notFound,
.noData {
	flex-grow: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	&>div {
		padding: 10px 14px;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
	}
}