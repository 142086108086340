@import '../../styles/variables.scss';

.wrapper {
	flex-shrink: 0;
	max-width: 50%;
	width: 40%;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	box-shadow: 0 0 16px $shadowColorBlockInfo;
	overflow: auto;
}

.header {
	font-weight: normal;
	color: $colorSecondary;
}

.data {
	margin: 0 -16px;
	height: 100%;
	min-height: 150px;
	overflow: auto;

	&>div,
	&>p {
		padding: 0 16px;
	}

	&Fragment {
		white-space: pre-wrap;
		word-break: break-word;
		text-align: left;

		&:not(:first-child) {
			margin-top: 10px;
		}

		&:not(:last-child) {
			padding-bottom: 10px;
			border-bottom: $borderSection;
		}

		&Title {
			margin-bottom: 5px;
		}

		&Key {
			font-weight: bold;
		}

		&Link {
			color: $colorSecondary;
			text-decoration: underline;
			cursor: pointer;
		}

		&Text {
			margin-top: 10px;
		}
	}
}