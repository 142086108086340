@import '../../styles/variables.scss';

.container {
	flex-grow: 1;
	padding: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	box-shadow: 0 0 16px $shadowColorBlockInfo;
}

.document {

	&Wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 10px;
		overflow: auto;
	}

	&Title {
		font-weight: normal;
		color: $colorSecondary;
	}

	&NoDataTitle {
		font-size: 13px;
	}

	&Textarea {
		flex-grow: 1;
		padding: 10px;
		border: $borderSection;
		border-radius: 4px;
		outline: none;
		resize: none;
		background-color: inherit;
		color: inherit;
		font-size: inherit;
		font-family: inherit;

		&:hover {
			border: 1px solid #000;
		}

		&:focus {
			padding: 9px;
			border: 2px solid $colorSecondary;
		}
	}

	&Buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&Params {
			cursor: pointer;
			text-decoration: underline;
		}
	}
}

.failedText,
.loading {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}