@import '../../../styles/variables.scss';

.sender {
	padding: 8px;
	border-radius: 0 0 10px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	background-color: #f4f7f9;
}

.button {
	min-width: 33px;
	width: 33px;
	height: 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	border-radius: 8px;
	background-color: $colorSecondary;
	transition: 0.3s;
	will-change: transform;
	cursor: pointer;

	&:hover {
		background-color: rgb(0, 102, 179);
	}

	&:disabled {
		background-color: $colorSecondaryLight;
		cursor: not-allowed;
	}

	&:not(:disabled):active {
		box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, .2);
		transform: scale(0.95);
	}
}