@import '../../styles/variables.scss';

.container {
	position: fixed;
	top: 10vh;
	right: 40px;
	width: 400px;
	height: 80vh;
	border-radius: 10px;
	box-shadow: 0 2px 10px 1px #b5b5b5;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	z-index: 30;
}

.reconnection {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	color: white;
	-webkit-animation: blink 0.8s ease infinite alternate;
	animation: blink 0.8s ease infinite alternate;
	z-index: 31;
}

.header {
	padding: 20px 30px;
	width: 100%;
	border-radius: 10px 10px 0 0;
	background-color: $colorSecondary;
	font-weight: normal;
	font-size: 24px;
	color: #fff;
	text-overflow: ellipsis;
	overflow: hidden;
	cursor: move;

	&Wrapper {
		position: relative;
	}
}

.buttonCloseChat {
	position: absolute;
	top: 50%;
	right: 20px;
	border: none;
	outline: none;
	background: transparent;
	transform: translateY(-50%);
	cursor: pointer;
	z-index: 32;
}

.controls {
	padding: 8px;
	display: flex;
	gap: 8px;
	background-color: #f4f7f9;
}

@-webkit-keyframes blink {
	0% {
		background-color: rgba(0, 0, 0, .4);
	}

	to {
		background-color: rgba(0, 0, 0, .3);
	}
}

@keyframes blink {
	0% {
		background-color: rgba(0, 0, 0, .4);
	}

	to {
		background-color: rgba(0, 0, 0, .3);
	}
}

.button {
	min-width: 33px;
	width: 33px;
	height: 33px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	border-radius: 8px;
	background-color: $colorSecondary;
	transition: 0.3s;
	will-change: transform;
	cursor: pointer;

	&:hover {
		background-color: rgb(0, 102, 179);
	}

	&:disabled {
		background-color: $colorSecondaryLight;
		cursor: not-allowed;
	}

	&:not(:disabled):active {
		box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, .2);
		transform: scale(0.95);
	}
}