@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.consoleWrapper {
	position: absolute;
	top: 56px;
	right: 0;
	min-width: 300px;
	max-width: calc(100vw - 450px);
	height: calc(100vh - 56px);
	border-left: $borderSection;
	box-shadow: -1px 4px 6px $shadowColor;
	background: $backgroundColorBtn;
	text-align: left;
}

.console {
	height: 100%;
	padding: 16px;
	overflow: auto;

	&Inner {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;

		&Bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
	}
}

.loading,
.noData,
.failed {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.tagClose {
	position: absolute;
	bottom: 85px;
	left: -50px;
	width: 80px;
	padding: 4px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	box-shadow: 0 -3px 6px $backgroundColorBtn;
	background-color: $backgroundColorBtn;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}