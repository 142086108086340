@import '../../../../styles/variables.scss';

.footer {
	padding: 8px;
	border-top: 1px solid rgba(224, 224, 224, 1);

	&BoxShadow {
		box-shadow: 0 -5px 10px 0 $backgroundColor;
	}
}

.selectedRow {
	margin-bottom: 8px;
	padding: 2px 8px;
	border-radius: 4px;
	background-color: rgba(25, 118, 210, 0.08);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form {
	width: 480px;
	flex-grow: 1;
	display: flex;
	gap: 8px;

	&ExistAbbreviation {
		position: absolute;
		bottom: -11px;
		left: 0;
		min-width: 200px;
		font-size: 10px;
	}
}

.pagination {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}