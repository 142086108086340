@import '../../styles/variables.scss';

.searchSpeaker {
	padding: 8px 8px 0;
	text-align: center;

	&Title {
		margin-bottom: 8px;
		color: $colorSecondary;
		font-weight: normal;
	}
}