@import '../../../../styles/variables.scss';

.footer {
	padding: 8px;
	border-top: 1px solid rgba(224, 224, 224, 1);

	&BoxShadow {
		box-shadow: 0 -5px 10px 0 $backgroundColor;
	}
}

.selectedRow {
	margin-bottom: 8px;
	padding: 2px 8px;
	border-radius: 4px;
	background-color: rgba(25, 118, 210, 0.08);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.formApproveCandidates {
	margin-bottom: 8px;
	display: flex;
	justify-content: flex-end;
	gap: 8px;

	&NoDataTitle {
		font-size: 13px;
	}
}

.blockFillGradientTop,
.blockFillGradientBottom {
	position: fixed;
	left: 0;
	width: calc(100% - 4px);
	height: 10px;
}

.blockFillGradientTop {
	top: 0;
	border-radius: 3px 3px 0 0;
	background: linear-gradient($backgroundColor, transparent);
}

.blockFillGradientBottom {
	bottom: 0;
	border-radius: 0 0 3px 3px;
	background: linear-gradient(transparent, $backgroundColor);
}

.blockNoOptions {
	font-size: 12px;
}

.tag {
	margin-right: 3px;
	font-size: 11px;
	text-align: left;
}

.pagination {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}