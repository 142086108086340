@import '../../../styles/variables.scss';

.sidebar {
	position: absolute;
	top: 56px;
	left: 0;
	width: 240px;
	height: calc(100vh - 56px);
	border-right: $borderSection;
	display: flex;
	flex-direction: column;
	background-color: $backgroundColorLightening;
	box-shadow: 1px 2px 6px $shadowColor;
	text-align: left;
}

.navbar {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
	position: relative;

	&FailedText {
		height: 100%;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 24px;
		text-align: center;
		font-size: 14px;
		overflow: hidden;
	}

	&Loading {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&FailedUpdate {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&NoUsers {
		height: 100%;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&User {
		font-size: 16px;

		&:not(:last-child) {
			margin-bottom: 12px;
		}

		&List {
			padding: 16px;
			overflow: auto;
		}

		&Link {
			padding: 4px 8px;
			border-left: 3px solid transparent;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 4px;
			cursor: pointer;

			&:hover {
				border-left: 3px solid #c4c4c4;
			}

			&LeftBlock {
				gap: 8px;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			&Active {
				padding: 4px 8px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				border-left: 3px solid $colorSecondary;
				border-radius: 0 6px 6px 0;
				background-color: $backgroundColorBtn;
				color: $colorSecondary;
				cursor: default;
			}
		}
	}
}

.functionButtons {

	&AddUser {
		margin: 24px 0 24px;
		color: $colorSecondary;
		word-wrap: break-word;
		text-align: center;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&Tabs {
		display: flex;
		border-top: 1px solid $colorPrimary;
	}

	&Tab {
		width: 50%;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $colorSecondary;
		color: #fff;
		transition: 0.3s;
		cursor: pointer;
		overflow: hidden;

		&NonActive {

			background-color: inherit;
			color: $colorPrimary;

			&:hover {
				color: $colorSecondary;
			}
		}
	}
}