@import '../../styles/variables.scss';

.chat {
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&Message {
		&List {
			flex-grow: 1;
			margin-inline: -16px;
			overflow-y: scroll;
			mask-image: $linearGradientFromTopToBottom, $linearGradientFromRightToLeft;
		}

		&Init {
			padding: 16px;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&Item {
			padding: 5px 16px;
			width: 100%;
			display: flex;
			white-space: pre-wrap;
			word-wrap: break-word;

			// &:first-child {
			// 	padding-top: 0;
			// }

			// &:last-child {
			// 	padding-bottom: 0;
			// }
		}

		&Text {
			padding: 15px;
			max-width: 90%;
			border-radius: 10px;
			background-color: #e6e6e6;
			text-align: left;

			&Client {
				background-color: $colorSecondaryLight3;
			}
		}

		&LoaderDots {
			display: inline-block;
			height: 4px;
			width: 4px;
			border-radius: 50%;
			background: grey;
			margin-right: 2px;
			-webkit-animation: bounce .5s ease infinite alternate;
			animation: bounce .5s ease infinite alternate;

			&:first-child {
				-webkit-animation-delay: .2s;
				animation-delay: .2s
			}

			&:nth-child(2) {
				-webkit-animation-delay: .3s;
				animation-delay: .3s
			}

			&:nth-child(3) {
				-webkit-animation-delay: .4s;
				animation-delay: .4s
			}
		}
	}

	&Form {
		display: flex;
		gap: 8px;
		justify-content: flex-end;
		align-items: center;
		position: relative;
	}

	&Button {
		min-width: 33px;
		width: 33px;
		height: 33px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		outline: none;
		border-radius: 8px;
		background-color: $colorSecondary;
		transition: 0.3s;
		will-change: transform;
		cursor: pointer;

		&:hover {
			background-color: rgb(0, 102, 179);
		}

		&:disabled {
			background-color: $colorSecondaryLight;
			cursor: not-allowed;
		}

		&:not(:disabled):active {
			box-shadow: inset 0 0 5px 5px rgba(0, 0, 0, .2);
			transform: scale(0.95);
		}
	}
}

@-webkit-keyframes bounce {
	0% {
		transform: translateY(0)
	}

	to {
		transform: translateY(5px)
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0)
	}

	to {
		transform: translateY(5px)
	}
}