.image {
	width: 100%;
	height: 100%;
	border-radius: 12px;
	overflow: hidden;

	&>img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}