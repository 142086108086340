@import '../../styles/variables.scss';

.container {
	display: flex;
	flex-direction: column;
	padding: 8px 16px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorRow;
	cursor: move;

	&Opacity {
		opacity: 0;
	}
}

.actionBlock {
	display: flex;
	align-items: center;
	gap: 12px;

	&Opacity {
		opacity: 0.5;
	}
}

.additionally {
	margin: 8px auto 4px;
	text-align: center;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.categoryDataBlock {
	margin: 2px 0 4px;
	padding: 3px 16px 8px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorRow;

	&>legend {
		padding: 0 6px;
	}

	&Line {
		margin: 8px 0 4px;
		text-align: center;
		width: 100%;
		position: relative;
		border: 1px solid transparent;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: calc(50% - 15px);
			border-top: $borderSection;
		}

		&::after {
			left: initial;
			right: 0;
		}

		&Title {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&Button {
			text-align: center;

			&>span {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}