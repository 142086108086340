@import '../../../styles/variables.scss';

.container {
	padding: 16px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorRow;
}

.block {
	display: flex;
	align-items: center;
	gap: 12px;

	&:not(:first-child) {
		margin-top: 12px;
	}
}