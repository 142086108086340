.screenLock {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.3);
	z-index: 1301;

	&Wrapper {
		padding: 10px 25px;
		border-radius: 5px;
		background-color: rgba(255, 255, 255, 0.9);
	}
}