@import '../../../styles/variables.scss';

// для Firefox
@-moz-document url-prefix() {

	.wrapperDuplicatePhrases,
	.thead {
		background-color: rgba(255, 255, 255, 0.9);
	}
}

.wrapper {
	height: 100%;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&DuplicatePhrases {
		text-align: right;
		border-radius: inherit;
	}
}

.container {
	height: 100%;
	overflow: auto;
}

.table {
	// min-width: 100%;
	border-spacing: 0;
}

.thead {
	position: sticky;
	top: 0;
	z-index: 10;

	&BoxShadow {
		box-shadow: 0 5px 10px 0 $backgroundColor;
		backdrop-filter: blur(8px);
	}
}

.th {
	padding: 8px 4px;
	border-bottom: 1px solid #e0e0e0;
	border-radius: 4px 4px 0 0;
	vertical-align: top;
	font-weight: normal;
	color: $colorSecondary;
	position: relative;

	&Title {
		margin-bottom: 6px;
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 14px;
		cursor: pointer;

		&Select {
			margin-top: 33px;
			display: flex;
			justify-content: flex-end;
		}
	}
}

.tbody {
	& .tr {
		&Selected {
			background-color: rgba(25, 118, 210, 0.08);
		}

		&:hover {
			background-color: $backgroundColorRow;

			& .tdButtons {
				display: flex;
			}
		}

		& .td {
			height: 18px;
			padding: 0 4px;
			border-bottom: 1px solid #e0e0e0;
			text-align: right;
			position: relative;

			&Select {
				display: flex;
				gap: 5px;
				justify-content: flex-end;
			}

			&Buttons {
				display: none;
				gap: 5px;

				&>button {
					border: none;
					outline: none;
					background-color: transparent;
					color: $colorPrimary;

					&:hover {
						color: $colorSecondary;
					}
				}
			}
		}
	}
}

.tag {
	margin-right: 3px;
	font-size: 11px;
	text-align: left;
	cursor: pointer;

	&Strike,
	&:hover {
		text-decoration: line-through;
	}

	&HighlightFilter {
		border-radius: 3px;
		background-color: $colorSecondaryLight2;
		color: #000;
	}
}

.cellInput {
	width: 100%;
	height: 100%;
	padding: 0 2px;
	border: none;
	background: inherit;
	outline: none;
	font-size: inherit;
	font-family: inherit;
	color: $colorPrimary;
	text-overflow: ellipsis;

	&:focus {
		border: 1px solid $colorPrimary;
		border-radius: 3px;
		background-color: rgba(0, 0, 0, 0.07);
	}
}

// .blockFillGradientTop,
// .blockFillGradientBottom {
// 	position: fixed;
// 	left: 0;
// 	width: calc(100% - 4px);
// 	height: 10px;
// }

// .blockFillGradientTop {
// 	top: 0;
// 	border-radius: 3px 3px 0 0;
// 	background: linear-gradient($backgroundColor, transparent);
// }

// .blockFillGradientBottom {
// 	bottom: 0;
// 	border-radius: 0 0 3px 3px;
// 	background: linear-gradient(transparent, $backgroundColor);
// }

.blockNoOptions {
	font-size: 12px;
}

.checkbox {
	accent-color: $colorSecondary;
}

.resizer {
	&Wrapper {
		position: absolute;
		right: -7px;
		top: 4px;
		height: 25px;
		width: 14px;
		display: flex;
		justify-content: center;
		cursor: col-resize;
		user-select: none;
		touch-action: none;
		z-index: 10;
		opacity: 0;

		&:hover {
			opacity: 1;
		}
	}

	&Delimiter {
		width: 3px;
		height: 100%;
		background: $colorPrimary;
		border-radius: 2px;
	}
}

.notFound {
	padding: 8px 0;
	text-align: center;
}