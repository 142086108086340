@import '../../../styles/variables.scss';

.sidebar {
	flex-shrink: 0;
	left: 0;
	width: 400px;
	height: calc(100vh - 56px);
	// border-right: $borderSection;
	display: flex;
	flex-direction: column;
	background-color: $backgroundColorLightening;
	text-align: left;
	transition: 0.3s;
	position: relative;
}

.navbar {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
	position: relative;

	&FailedText {
		height: 100%;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 24px;
		text-align: center;
		font-size: 14px;
		overflow: hidden;
	}

	&Loading {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&FailedUpdate {
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&NoQuestions {
		height: 100%;
		padding: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	&Search {
		padding: 8px;
		display: flex;
		flex-direction: column;
		gap: 8px;

		&Block {
			display: flex;
			gap: 8px;
		}

		&Counter {
			margin-left: 6px;
			font-size: 12px;
		}

		&Toogle {
			margin: 0 auto;
			text-decoration: underline;
			cursor: pointer;
		}

		&SelectAll {
			padding: 0 6px;
			display: flex;
			align-self: start;
			gap: 8px;
			font-size: 12px;
			cursor: pointer;

			&>input {
				accent-color: $colorSecondary;
			}
		}
	}

	&Questions {
		height: 100%;
		padding: 8px;
		font-size: 12px;
		overflow: auto;
		mask-image: linear-gradient(transparent 0%, #000 8px, #000 calc(100% - 8px), transparent 100%);

		&Link {
			padding: 2px 4px;
			border-left: 3px solid transparent;
			border-radius: 0 6px 6px 0;
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 4px;
			cursor: pointer;

			&:hover {
				border-left: 3px solid #c4c4c4;
				background-color: $backgroundColorRow;
			}

			&:hover &Icon {
				display: block;
			}

			&LeftBlock {
				display: flex;
				gap: 8px;
				overflow: hidden;

				&>input {
					accent-color: $colorSecondary;
				}

				&>span {
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			&Active {
				padding: 2px 4px;
				border-left: 3px solid $colorSecondary;
				border-radius: 0 6px 6px 0;
				display: flex;
				justify-content: space-between;
				color: $colorSecondary;
				background-color: $backgroundColorBtn;
				align-items: center;
				cursor: default;
			}

			&Icon {
				display: none;

				&Active {
					display: block;
				}
			}
		}
	}
}

.functionButtons {
	display: flex;
	flex-direction: column;

	&AddQuestion {
		align-self: center;
		margin: 20px auto;
		color: $colorSecondary;
		word-wrap: break-word;
		text-align: center;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}

	&Tabs {
		border-top: 1px solid $colorPrimary;
		display: flex;
	}

	&Tab {
		width: 50%;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $colorSecondary;
		color: #fff;
		transition: 0.3s;
		cursor: pointer;
		overflow: hidden;

		&:not(:last-child) {
			border-right: 1px solid $colorPrimary;
		}

		&NonActive {
			background-color: inherit;
			color: $colorPrimary;

			&:hover {
				color: $colorSecondary;
			}
		}
	}
}