@import '../../styles/variables.scss';

.info {
	min-width: 240px;
	width: 240px;
	min-height: 480px;
	display: flex;
	flex-direction: column;
	gap: 16px;

	&Title {
		border-radius: 6px;
		text-align: left;

		&Top {
			display: flex;
			justify-content: space-between;
			font-size: 14px;
			color: $colorSecondary;
		}

		&ModelName {
			width: 192px;
			word-wrap: break-word;
		}

		&ModelStatus {
			margin-top: 8px;
			display: flex;
			justify-content: space-between;
			color: $colorSecondary;
		}
	}

	&Trash,
	&Reload {
		&:hover {
			transform: scale(1.1);
		}
	}

	&Reload {
		&:active {
			transform: rotate(90deg);
		}
	}

	&Accuracy {
		border-radius: 6px;

		&Title {
			font-weight: bold;
		}
	}

	&Other {
		flex-grow: 1;
		flex-shrink: 0;
		padding: 16px;
		border: $borderBlockInfo;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
		overflow: auto;

		&Loading {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&Pair {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			gap: 3px;

			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}

	&Key {
		text-align: left;
		font-weight: bold;
	}

	&Value {
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: right;
	}
}

.shadowFuture {
	box-shadow: 0 0 4px $gray-200;
}

.shadowCurrent {
	box-shadow: 0 0 4px $green;
}

.shadowPrevious {
	box-shadow: 0 0 4px $colorSecondary;
}

// для progress-bar'а если понадобится
// .container {
// 	position: relative;
// }

// .label {
// 	position: absolute;
// 	top: 50%;
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// }