@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.question {
	position: absolute;
	top: 0;
	right: 0;
	width: 500px;
	height: 100%;
	padding: 16px 0;
	border-left: $borderSection;
	background: $backgroundColor;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 16px;
	overflow: auto;

	&Inner {
		padding-top: 8px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 12px;
		overflow: auto;
		text-align: left;
	}

	&Buttons {
		display: flex;
		gap: 16px;
		padding: 0 16px;
	}
}

.tagClose {
	position: absolute;
	right: 470px;
	bottom: 85px;
	padding: 4px 8px 5px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}