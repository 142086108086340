@import '../../styles/variables.scss';

.wrapper {
	height: calc(100vh - 56px);
	margin-top: 56px;
	margin-left: 240px;
	padding: 16px;
	display: flex;
	gap: 16px;
	box-shadow: inset -1px -1px 6px $shadowColor;
	overflow: auto;
	position: relative;

	&Matrix {
		min-height: 480px;
		border: $borderBlockInfo;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
		flex-grow: 1;
		overflow: hidden;
	}
}

.shadowFuture {
	box-shadow: 0 0 4px $gray-200;
}

.shadowCurrent {
	box-shadow: 0 0 4px $green;
}

.shadowPrevious {
	box-shadow: 0 0 4px $colorSecondary;
}

.loading,
.notFound,
.noData {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&>div {
		padding: 10px 14px;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
	}
}

.loading {
	position: absolute;
	width: calc(100% - 32px);
	height: calc(100% - 32px);
}

.training {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 400px;
	height: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	transform: translate(-50%, -50%);
	// z-index: -1;

	&Img {
		width: 90%;
		height: 90%;
		border-radius: 50%;
		background-image: url('../../assets/training.gif');
		background-size: cover;
		-webkit-mask-image: -webkit-radial-gradient(center, ellipse cover, rgba(0, 0, 0, 1) 0%,
				rgba(0, 0, 0, 0.7) 40%,
				rgba(0, 0, 0, 0.4) 60%,
				rgba(0, 0, 0, 0) 70%);
		mask-image: radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7) 40%, rgba(0, 0, 0, 0.4) 60%, rgba(0, 0, 0, 0) 70%);
	}

	&Title {
		padding: 6px 12px;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
	}
}