@import '../../styles/variables.scss';

.noData,
.failed,
.loading {
	display: flex;
	justify-content: center;
	align-items: center;
}

.protocol {
	padding-block: 16px;
	width: 50%;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	text-align: left;
	white-space: pre-wrap;
	overflow: auto;

	&>div {
		height: 100%;
		padding-inline: 16px;
		overflow: auto;
	}
}