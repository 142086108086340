@import '../../styles/variables.scss';

.conditionDataBlock {
	margin-bottom: 4px;
	padding: 3px 16px 8px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorCondition;

	&>legend {
		padding-inline: 6px;
	}

	&Line {
		margin: 8px 0 4px;
		text-align: center;
		width: 100%;
		position: relative;
		border: 1px solid transparent;

		&::before,
		&::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: calc(50% - 15px);
			border-top: $borderSection;
		}

		&::after {
			left: initial;
			right: 0;
		}

		&Title {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&Button {
			margin-inline: auto;
			padding: 2px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: none;
			border-radius: 4px;
			background-color: transparent;
			color: inherit;
			font-size: inherit;
			text-decoration: underline;
			cursor: pointer;

			&:focus-visible {
				outline: 2px solid $colorSecondary;
			}
		}
	}
}