@import '../../styles/variables.scss';

.zoom-wrapper {
	position: relative;
	width: 100%;
	height: 100%;

	.plane-wrap {
		// width: calc(100% - 100px);
		max-width: calc(100% - 80px);
		margin-left: 16px;
		margin-top: 16px;
		min-height: calc(100% - 64px);
		// height: calc(100% - 80px);
		max-height: calc(100% - 64px);
		// scrollbar-width: thin;
		overflow: auto;
		padding: 16px;
		display: flex;

		&.dragging {
			cursor: grab;
		}
	}

	// .plane-wrap-testMatrix {
	// @media (max-width: 1255px) {
	// 	min-height: calc(100% - 96px);
	// 	max-height: calc(100% - 96px);
	// }
	// }
}

.zoomed-plane {
	transform-origin: top left;
	// height: auto;
	width: 100%;
	max-width: 100%;
	display: flex;
	// justify-content: center;
	transition: 0.3s;
}

.zoomed-plane-height {
	height: 100%;
}

.card-ConfusionMatrix .plane-wrap {
	background: lighten($light-primary, 2%);
}

.zoomer {
	position: absolute;
	width: 106px;
	height: 26px;
	bottom: 32px;
	left: 16px;
	z-index: 10;
	background: #FFFFFF;
	border: 1px solid #E7E7E7;
	box-shadow: 0px 3px 8px rgba(128, 130, 136, 0.1);
	border-radius: 2px;
	line-height: 16px;
	vertical-align: middle;

	>span {
		display: block;
		position: absolute;
		text-align: center;
		-moz-user-select: none;
		user-select: none;
	}

	.zoom-out,
	.zoom-in {
		width: 24px;
		height: 24px;
		top: 0;
		cursor: pointer;
		padding: 4px;

		&:hover,
		&:active {
			color: $colorSecondary;
		}
	}

	.zoom-out {
		left: 1px;
	}

	.zoom-in {
		right: 1px;
	}

	.zoom-level {
		left: 26px;
		right: 26px;
		top: 5px;
		height: 16px;
		border-left: 1px solid $gray-200;
		border-right: 1px solid $gray-200;
	}
}

.btnClasses {
	position: absolute;
	width: 106px;
	height: 26px;
	bottom: 32px;
	left: 130px;
	z-index: 10;
	background: #FFFFFF;
	border: 1px solid #E7E7E7;
	box-shadow: 0px 3px 8px rgba(128, 130, 136, 0.1);
	border-radius: 2px;
	line-height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.3s;
	overflow: hidden;

	&:hover {
		color: $colorSecondary;
	}
}

.classItem {
	padding: 3px 0;
}

.classItem:not(:last-of-type) {
	border-bottom: 1px solid $shadowColor;
}

.wrapper-buttons-matrix {
	position: absolute;
	bottom: 32px;
	left: 244px;
	display: flex;
	justify-content: center;
	gap: 8px;

	// @media (max-width: 1255px) {
	// 	bottom: 66px;
	// 	left: 16px;
	// }
}

.matrixBtn {
	width: 80px;
	height: 26px;
	z-index: 10;
	background: #FFFFFF;
	border: 1px solid #E7E7E7;
	box-shadow: 0px 3px 8px rgba(128, 130, 136, 0.1);
	border-radius: 2px;
	line-height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.3s;
	overflow: hidden;

	&:hover {
		color: $colorSecondary;
	}

	// @media (max-width: 1255px) {
	// 	width: 106px;
	// }

	&Active {
		background: $colorSecondary;
		color: #fff;

		&:hover {
			color: #fff;
		}
	}
}

// .matrix-wrap {
// 	height: 100%;
// 	min-height: calc(100vh - 450px);
// 	max-height: calc(100vh - 160px);
// }

// .soloMatrix {
// height: calc(100vh - 130px);
// }