@import '../../styles/variables.scss';

.container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 56px;
	display: flex;
	border-bottom: $borderSection;
	box-shadow: 0 1px 6px $shadowColor;
	background-color: $backgroundColorLightening;
	z-index: 30;

	@media (max-width: 1024px) {
		width: 1024px;
	}
}

.header {
	width: 240px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $colorSecondary;
	color: #fff;
	font-size: 20px;

	&Wide {
		width: 400px;
	}
}

.controls {
	flex-grow: 1;
	padding: 0 16px;
	display: flex;
	justify-content: space-between;
	gap: 16px;

	&Section {
		display: flex;
		align-items: center;
		gap: 16px;

		// блоки
		&>div {
			display: flex;
			align-items: center;
			gap: 8px;

			// кнопки
			&>button {
				width: 40px;
				height: 40px;
				border-radius: 13px;
				border: none;
				outline: none;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $colorSecondary;
				transition: 0.3s;
				cursor: pointer;
				will-change: transform;

				&:not(:disabled):hover {
					transform: scale(1.1);
				}

				&:disabled {
					background-color: $colorSecondaryLight;
					cursor: not-allowed;
				}
			}
		}
	}
}