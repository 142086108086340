@import '../../../styles/variables.scss';

.scriptCode {
	flex-grow: 1;

	&Wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}

.loading,
.failed {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}