@import '../../../styles/variables.scss';

.container {
	padding: 16px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorRow;
	display: flex;
	gap: 12px;

	&Opacity {
		opacity: 0.5;
	}
}