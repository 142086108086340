@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.analysis {
	position: absolute;
	top: 0;
	right: 0;
	width: 340px;
	height: 100%;
	padding: 16px;
	border-left: $borderSection;
	background: $backgroundColor;
	overflow: auto;

	&Title {
		margin-bottom: 16px;
		font-weight: normal;
		color: $colorSecondary;
	}

	&Result {

		&>div,
		&>p {
			margin: 16px auto 0;
			padding-top: 16px;
			border-top: $borderSection;
		}

		&Pair {
			text-align: left;

			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}

		&Key {
			font-weight: bold;
		}
	}
}

.tagClose {
	position: absolute;
	right: 310px;
	bottom: 85px;
	padding: 4px 8px 5px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}

.renameForm {
	width: 100%;
	display: flex;
	gap: 3px;
	position: relative;

	&Input {
		width: 100%;
		padding: 0 4px;
		outline: none;
		font-size: inherit;
		color: $colorPrimary;

		&Warn {
			border: 1px solid $red;
			box-shadow: 0 0 5px $red;
		}
	}

	&Btn {
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: inherit;
	}
}

.loading,
.failed,
.empty {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}