@import '../../../styles/variables.scss';

.inputFile {
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	z-index: -1;
}

.labelFile {
	display: block;
	margin: 8px 0 4px;
	width: 100%;
	cursor: pointer;

	&Name {
		padding: 8px 14px;
		height: 33px;
		border: $borderSection;
		border-radius: 4px;
		border-color: rgba(0, 0, 0, 0.23);
		font-size: 13px;
		color: rgba(0, 0, 0, 0.6);
		letter-spacing: 0.00938em;
		white-space: nowrap;
		text-align: left;
		text-overflow: ellipsis;
		overflow: hidden;

		&:hover {
			border: 1px solid #000;
		}
	}
}

.image {
	margin-top: 8px;
	max-width: 100%;
	max-height: 150px;
}