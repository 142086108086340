.rulesBlock {
	margin-top: 8px;
	display: flex;
	align-items: flex-start;
	gap: 8px;

	&:hover .deleteButton {
		opacity: 1;
	}
}

.deleteButton {
	opacity: 0;
	transition: 0.1s;
}