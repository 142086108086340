@import './styles/variables.scss';

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: $colorSecondaryLight;
  border-radius: 4px;

  &:hover {
    background-color: $colorSecondary;
  }
}

::-webkit-scrollbar-corner {
  background-color: $backgroundColor;
  border-radius: 4px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  min-width: 1024px;
  background-color: $backgroundColor;
  font-family: 'Inter', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $colorPrimary;
  font-weight: 400;
  font-size: 11px;
  text-align: center;
  overflow-y: hidden;
}