@import '../../styles/variables.scss';

.checkResult {
	height: 100%;
	display: flex;
	flex-direction: column;

	&EditorWrapper {
		padding: 16px;
		height: 100%;
		overflow: auto;
	}
}

.btnUpdate {
	margin-top: 20px;
	border: none;
	background: transparent;
	color: inherit;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.wrapper {
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 20px;
	text-align: left;
}

.line {
	padding: 8px;
	border: $borderBlockInfo;
	border-radius: 0 10px 10px 10px;
	position: relative;

	&:first-child {
		margin-top: 16px;
	}

	&Toxicity {
		background-color: $lightRed;
	}

	&Active {
		background-color: $colorSecondaryLight3;
	}

	&Word {
		display: inline-block;

		&:not(:last-child) {
			margin-right: 3px;
		}

		&ActiveBlue {
			// font-weight: bold;
			text-shadow: 0 0 1px $colorSecondary; // lifehack (пожирнее и не смещается длина)
			color: $colorSecondary;
		}

		&ActiveRed {
			text-shadow: 0 0 1px $red; // lifehack (пожирнее и не смещается длина)
			color: $red;
		}
	}
}

.speaker {
	&Wrapper {
		position: absolute;
		top: -16px;
		left: 0;
		color: $colorSecondary;
		font-size: 12px;

		&:hover .speakerInfo {
			display: block;
		}
	}

	&Info {
		position: absolute;
		top: 20px;
		display: none;
		padding: 8px 16px;
		border-radius: 6px;
		box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
		background: $backgroundColor;
		color: $colorPrimary;
		white-space: nowrap;
		z-index: 10;

		&Header {
			margin-top: 8px;
			position: relative;

			&Border:before {
				content: "";
				position: absolute;
				top: -4px;
				left: 20%;
				width: 60%;
				border-top: $borderSection;
			}
		}
	}
}

.failedText,
.loading {
	margin: 0 auto;
	padding: 16px;
	width: 70%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}