@import '../../../styles/variables.scss';

.wrapper {
	flex-grow: 1;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	overflow: hidden;
}

.container {
	height: 100%;
	overflow: auto;
}

.table {
	min-width: 100%;
	border-spacing: 0;
}

.thead {
	position: sticky;
	z-index: 10;
	top: 0;
	background-color: $backgroundColor;

	&BoxShadow {
		box-shadow: 0 5px 10px 0 $backgroundColor;
		backdrop-filter: blur(8px);
	}
}

.th {
	padding: 8px 4px;
	border-bottom: 1px solid #e0e0e0;
	border-radius: 4px 4px 0 0;
	vertical-align: top;
	font-weight: normal;
	color: $colorSecondary;
	position: relative;

	&Title {
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 14px;
		cursor: pointer;
	}
}

.tbody {

	& .tr {

		&:hover {
			background-color: $backgroundColorRow;
		}

		& .td {
			min-height: 18px;
			max-width: 200px;
			position: relative;

			&[data-id='variables']:hover .tdPopup:not(:empty) {
				position: absolute;
				top: 18px;
				left: 0;
				display: block;
			}

			&Text {
				min-height: 18px;
				padding: 0 4px;
				border-bottom: 1px solid #e0e0e0;
				text-align: left;
				line-height: 17px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
			}

			&Name {
				cursor: pointer;
			}

			&Popup {
				padding: 8px;
				display: none;
				border-radius: 6px;
				box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
				background: $backgroundColor;
				text-align: left;
				z-index: 20;

				&Title:not(:first-child) {
					margin-block: 4px;
				}

				&Title:first-child {
					margin-bottom: 4px;
				}

				&Item {
					margin-left: 8px;
				}
			}
		}
	}
}

.notFound {
	padding: 8px 0;
	text-align: center;
}