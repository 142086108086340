@import '../../styles/variables.scss';

.blockInfo {
	padding: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	overflow: auto;
}

.shadowFuture {
	box-shadow: 0 0 4px $gray-200;
}

.shadowCurrent {
	box-shadow: 0 0 4px $green;
}

.shadowPrevious {
	box-shadow: 0 0 4px $colorSecondary;
}