@import '../../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 21;
}

.wrapperAnswer {
	position: absolute;
	top: 56px;
	right: 0;
	width: 360px;
	height: calc(100vh - 56px);
	padding: 16px;
	border-left: $borderSection;
	box-shadow: -1px 4px 6px $shadowColor;
	background: $backgroundColor;
	overflow: auto;
}