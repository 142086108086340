.container {
	&:hover .deleteButton {
		display: block;
	}

	&Opacity {
		opacity: 0.5;
	}
}

.deleteButton {
	display: none;
}