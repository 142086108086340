@import '../../../styles/variables.scss';

.form {

	&Inner {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&Microphone,
	&FileLabelIcon,
	&SubmitBtn {
		min-width: 32px;
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 8px;
		background-color: $colorSecondary;
		cursor: pointer;
		transition: 0.2s;
		will-change: transform;

		&:disabled {
			background: $colorSecondaryLight;
			cursor: not-allowed;
		}

		&:not(:disabled):active {
			box-shadow: inset 0 0 5px 5px rgba(144, 62, 62, 0.2);
			transform: scale(0.95);
		}
	}

	&Microphone {
		background-color: $gray-500;

		&:disabled {
			background: $gray-500;
		}

		&:not(:disabled):hover {
			background-color: $colorSecondary;
		}
	}

	&File {
		&Input {
			position: absolute;
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			z-index: -1;
		}

		&Label {
			width: 100%;
			display: flex;
			gap: 8px;

			&IconNotAllow {
				background: $colorSecondaryLight;
				cursor: not-allowed;

				&.formFileLabelIcon:active {
					box-shadow: none;
					transform: none;
				}
			}

			&Name {
				flex-grow: 1;
				padding: 8px;
				width: 150px; // странная работа
				border: $borderSection;
				border-radius: 4px;
				background-color: #e8e8e8;
				white-space: nowrap;
				text-overflow: ellipsis;
				text-align: center;
				overflow: hidden;
				cursor: pointer;

				&NotAllow {
					cursor: not-allowed;
				}
			}
		}
	}
}