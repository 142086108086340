@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 30;

	&DarkBackground {
		background-color: rgba(0, 0, 0, 0.1);
	}
}

.data {
	position: absolute;
	top: 56px;
	right: 0;
	min-width: calc((100% - 240px) / 2 - 8px);
	max-width: calc(100% - 32px);
	height: calc(100% - 56px);
	border-left: $borderSection;
	box-shadow: -1px 4px 6px $shadowColor;
	background: $backgroundColor;
	z-index: 20;

	&Wrapper {
		padding: 16px 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		text-align: left;
		overflow: auto;
	}

	&Top {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 12px;
		overflow: auto;

		&Main {
			flex-shrink: 0;
			margin: 0 16px;

			&Block {
				display: flex;
				align-items: center;
				gap: 12px;
			}

			&Fieldset {
				margin: 2px 0 4px;
				padding: 3px 16px 8px;
				max-height: 250px;
				border: $borderSection;
				border-radius: 4px;
				background: $backgroundColorRow;
				overflow: auto;

				&>legend {
					padding: 0 6px;
				}
			}
		}

		&Events {
			display: flex;
			flex-direction: column;
			gap: 12px;
			overflow: auto;

			&Title {
				margin-bottom: 6px;
				text-align: center;
				font-weight: normal;
				color: $colorSecondary;
			}

			&NoDataTitle {
				font-size: 13px;
			}

			&Block {
				margin: 0 16px;
				display: flex;
				gap: 12px;
			}

			&Actions {
				padding: 0 16px;
				display: flex;
				flex-direction: column;
				gap: 12px;
				overflow: auto;
			}
		}
	}

	&Buttons {
		margin: 0 16px;
		display: flex;
		gap: 16px;
	}
}

.loading,
.failed {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}