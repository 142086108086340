@import '../../../../styles/variables.scss';

.filterClasses {
	display: flex;
	gap: 4px;
}

.blockNoOptions {
	font-size: 12px;
}

.blockFillGradientTop,
.blockFillGradientBottom {
	position: fixed;
	left: 0;
	width: calc(100% - 4px);
	height: 10px;
}

.blockFillGradientTop {
	top: 0;
	border-radius: 3px 3px 0 0;
	background: linear-gradient($backgroundColor, transparent);
}

.blockFillGradientBottom {
	bottom: 0;
	border-radius: 0 0 3px 3px;
	background: linear-gradient(transparent, $backgroundColor);
}