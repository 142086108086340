@import '../../styles/variables.scss';

.template {
	flex-basis: 200px;
	flex-grow: 1;
	padding: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	overflow: auto;
	transition: 0.3s;

	&PartialHeight {
		flex-grow: 0;
	}
}

.noData,
.failed,
.loading {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.templateData {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 8px;

	&Top {
		margin-inline: -16px;
		padding-inline: 16px;
		overflow: auto;
	}

	&Bottom {
		display: flex;
		gap: 16px;
	}
}

.title {
	margin-top: 4px;
	font-size: 12px;
}