@import '../../styles/variables.scss';

.home {
	padding: 16px;
	min-width: 800px;
	min-height: 200px;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	background-position: center;
	background-size: cover;
	position: relative;

	&Version {
		position: absolute;
		top: 10px;
		right: 10px;
	}
}