@import '../../styles/variables.scss';

.container {
	padding: 16px;
	width: 50%;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	box-shadow: 0 0 16px $shadowColorBlockInfo;
}

.wordCloud {

	&Wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 8px;

		&Top {
			display: flex;
			gap: 8px;
		}
	}
}

.loading,
.failed,
.empty {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	&>div {
		padding: 10px 14px;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
	}
}