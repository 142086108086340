@import '../../../../styles/variables.scss';

.button {
	z-index: 10;
}

.button:hover .rangeBlock {
	display: flex;
	align-items: center;
	gap: 16px;
}

.active {
	box-shadow: 0 0 8px red;
}

.rangeBlock {
	position: absolute;
	top: 20px;
	left: 20px;
	width: 200px;
	padding: 10px 15px;
	display: none;
	border: $borderBlockInfo;
	border-radius: 8px;
	box-shadow: 0 0 15px $shadowColorBlockInfo;
	background-color: $backgroundColor;
	color: $colorPrimary;
	cursor: default;
}