@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 40;
	opacity: 1;
}

.server {
	position: absolute;
	top: 0;
	right: 0;
	padding: 26px;
	width: 240px;
	max-width: 90%;
	height: 100%;
	border-left: $borderSection;
	background: $backgroundColor;
	text-align: left;
	overflow: auto;

	&Title {
		margin-bottom: 25px;
		padding-bottom: 16px;
		border-bottom: 1px solid #DEDEDE;
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		font-weight: normal;
		color: $colorSecondary;
	}

	&Status,
	&StatusFailed {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		display: inline-block;
		background: $green;
	}

	&StatusFailed {
		background: $red;
	}

	&TrainerTitle,
	&ClusterTitle,
	&WebsocketTitle {
		margin: 16px 0;
		font-weight: bold;
	}

	&Item {
		margin: 7px 0 7px 16px;
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&FailedTime {
		margin-top: 4px;
		text-align: right;
		font-size: 10px;
		color: $red;
	}
}

.tagClose {
	position: absolute;
	right: 210px;
	bottom: 85px;
	padding: 4px 8px 5px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}