@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 25;
}

.errorsWrapper {
	position: absolute;
	top: 56px;
	right: 0;
	min-width: 300px;
	max-width: calc(100vw - 300px);
	height: calc(100vh - 56px);
	border-left: $borderSection;
	box-shadow: -1px 4px 5px $shadowColor;
	background: $backgroundColorBtn;
	text-align: left;
}

.errorsContainer {
	height: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.errors {
	flex-grow: 1;
	display: flex;
	gap: 16px;
	overflow-y: auto;

	@media (max-width: 950px) {
		flex-direction: column;
	}
}

.btns {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}

.loading,
.noData,
.failed {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loading {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.tagClose {
	position: absolute;
	bottom: 85px;
	left: -50px;
	width: 80px;
	padding: 4px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	box-shadow: 0 -3px 5px $backgroundColorBtn;
	background-color: $backgroundColorBtn;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}