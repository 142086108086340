@import '../../../../styles/variables.scss';

.playBtn {
	min-width: 26px;
	width: 26px;
	height: 26px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 50%;
	outline: none;
	background-color: $colorSecondary;
	cursor: pointer;

	&NotActive {
		background-color: $colorPrimary;
		cursor: not-allowed;
	}
}

.renameForm {
	display: flex;
	justify-content: space-between;
	gap: 5px;

	&Input {
		flex-grow: 1;
		color: $colorPrimary;
		font-size: 11px;
		border: $borderSection;
		border-radius: 6px;
		background-color: transparent;
		outline: none;
	}

	&Btn {
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: inherit;
	}
}

.speakerBlock {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	font-size: 11px;
	color: $colorPrimary;
}