@import '../../styles/variables.scss';

.info {
	height: 100%;
	padding: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	box-shadow: 0 0 16px $shadowColorBlockInfo;

	&Main {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		overflow: auto;
		text-align: left;

		&Login {
			margin: 8px 0;
			text-align: center;
			font-size: 14px;
		}
	}
}

.inputFile {
	position: absolute;
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	z-index: -1;
}

.labelFile {
	width: 200px;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 16px;
	cursor: pointer;
	overflow: hidden;
	border: $borderSection;
	position: relative;

	&:hover &Icon {
		bottom: 0;
	}

	&DefaultCursor {
		cursor: default;
	}

	&Image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&Icon {
		position: absolute;
		bottom: -40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.3);
		width: 100%;
		height: 40px;
		transition: 0.3s;
	}
}

.loading,
.failed {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}