@import '../../../styles/variables.scss';

.cloud {
	flex-grow: 1;
	width: 100%;
	z-index: 10;

	&Right {
		align-self: end;
	}

	&>div {
		max-width: 200px !important;
		white-space: normal !important;
		word-break: break-word;
		transition: 1.4s;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}

.reportColor {
	color: $colorSecondaryLight !important;
}

.transferColor {
	color: $green !important;
}

.finishColor {
	color: $red !important;
}

.filterByEntryConditions {
	font-size: 12px !important;
	color: #000 !important;
}