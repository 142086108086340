@import '../../styles/variables.scss';

.container {
	flex-grow: 1;
	padding: 16px;
	border: $borderBlockInfo;
	border-radius: 6px;
	background-color: $backgroundColorLightening;
	box-shadow: 0 0 16px $shadowColorBlockInfo;
}

.answers {

	&Wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		gap: 16px;
		text-align: left;
	}

	&TopBlock {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 10px;
	}

	&Question {
		flex-grow: 1;
		width: 20px; // странная работа, но для адаптива
		font-weight: normal;
		color: $colorSecondary;
		white-space: nowrap;
		text-overflow: ellipsis;
		cursor: pointer;
		overflow: hidden;

		&Expanded {
			white-space: initial;
			text-overflow: initial;
			overflow: initial;
		}
	}

	&List {
		flex-grow: 1;
		margin: 0 -16px;
		padding: 0 16px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		overflow: auto;

		&Empty {
			text-align: center;
		}
	}

	&BottomBlock {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		flex-wrap: wrap;

		&Params {
			cursor: pointer;
			text-decoration: underline;
		}

		&Btns {
			display: flex;
			flex-wrap: wrap;
			gap: 10px;
		}
	}
}

.failedText,
.loading {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}