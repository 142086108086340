@import '../../../styles/variables.scss';

.message {
	&List {
		margin: 4px 0;
		flex-grow: 1;
		width: 100%;
		overflow-y: scroll;
	}

	&Init {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&Item {
		padding: 5px 10px;
		width: 100%;
		display: flex;
		white-space: pre-wrap;
		word-wrap: break-word;

		&Client {
			justify-content: flex-end;
		}

		&Hidden,
		&AudioHidden {
			display: none;
		}
	}

	&Text {
		padding: 15px;
		max-width: 90%;
		border-radius: 10px;
		background-color: #e6e6e6;
		text-align: left;

		&Client {
			background-color: $colorSecondaryLight3;
		}

		&Audio {
			min-width: 70%;

			&Transcript {
				margin-top: 8px;
			}
		}

		&Buttons {
			margin-top: 8px;
			display: flex;
			flex-wrap: wrap;
			// grid-template-columns: repeat(4, 1fr);
			// grid-template-columns: repeat(auto-fill, minmax(100px, 4fr));
			gap: 4px;
		}

		&Button {
			flex-grow: 1;
			padding: 3px 6px;
			background-color: transparent;
			border: $borderSection;
			border-radius: 4px;
			outline: none;
			word-break: break-word;
			color: inherit;
			transition: 0.2s;

			&:not(:disabled):hover {
				border: 1px solid $colorSecondary;
				box-shadow: 0 0 5px $colorSecondaryLight;
				color: $colorSecondary;
				cursor: pointer;
			}
		}
	}

	&LoaderDots {
		display: inline-block;
		height: 4px;
		width: 4px;
		border-radius: 50%;
		background: grey;
		margin-right: 2px;
		-webkit-animation: bounce .5s ease infinite alternate;
		animation: bounce .5s ease infinite alternate;

		&:first-child {
			-webkit-animation-delay: .2s;
			animation-delay: .2s
		}

		&:nth-child(2) {
			-webkit-animation-delay: .3s;
			animation-delay: .3s
		}

		&:nth-child(3) {
			-webkit-animation-delay: .4s;
			animation-delay: .4s
		}
	}
}

@-webkit-keyframes bounce {
	0% {
		transform: translateY(0)
	}

	to {
		transform: translateY(5px)
	}
}

@keyframes bounce {
	0% {
		transform: translateY(0)
	}

	to {
		transform: translateY(5px)
	}
}