@import '../../../styles/variables.scss';

.container {
	padding: 16px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorRow;
}

.intervalBlock {
	margin: 16px 0;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 8px;
}

.weekDay,
.month {
	padding: 4px 8px;
	max-width: 70px;
	border: $borderSection;
	border-radius: 4px;
	word-wrap: break-word;
	font-size: 12px;
	transition: 0.3s;
	will-change: transform;

	&Access:hover {
		cursor: pointer;
		transform: scale(1.1);
	}

	&Checked {
		background-color: $colorSecondaryLight;
		color: #000;
	}
}

.month {
	min-width: 45px;
	text-align: center;
}

.dateTable {
	margin: -8px auto;
	border-spacing: 8px;

	& td {
		width: 30px;
		height: 30px;
		border: $borderSection;
		border-radius: 4px;
		text-align: center;
		font-size: 12px;
		transition: 0.3s;
		will-change: transform;
	}

	&Access:hover {
		cursor: pointer;
		transform: scale(1.1);
	}

	&Checked {
		background-color: $colorSecondaryLight;
		color: #000;
	}
}