@import '../../styles/variables.scss';

.wrapper {
	height: calc(100vh - 56px);
	margin-top: 56px;
	margin-left: 400px;
	padding: 16px;
	box-shadow: inset -1px -1px 6px $shadowColor;
	overflow: auto;
	position: relative;
}

.noData {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&>div {
		padding: 10px 14px;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
	}
}