@import '../../styles/variables.scss';

.wrapper {
	height: calc(100vh - 56px);
	margin-top: 56px;
	margin-left: 240px;
	padding: 16px;
	display: flex;
	gap: 16px;
	box-shadow: inset -1px -1px 6px $shadowColor;
	overflow: auto;
	position: relative;

	&Synthesis,
	&StressDictionary {
		min-height: 480px;
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	&Synthesis {
		min-width: 350px;
		width: 35%;
	}

	&StressDictionary {
		width: 65%;
	}
}

.noData {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&>div {
		padding: 10px 14px;
		border-radius: 6px;
		background-color: $backgroundColorLightening;
	}
}