.form {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 12px;

	&Fields,
	&FieldsInner {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	&Block,
	&Buttons {
		display: flex;
		gap: 12px;
	}
}