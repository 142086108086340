@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.configRobot {
	position: absolute;
	top: 0;
	right: 0;
	padding: 16px 0;
	width: 720px;
	max-width: 90%;
	height: 100%;
	border-left: $borderSection;
	background: $backgroundColor;

	&Wrapper {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: 16px;
		overflow: auto;
	}

	&Top {
		padding: 0 16px;
		text-align: right;
		overflow: auto;
	}

	&Bottom {
		margin-right: 16px;
		align-self: flex-end;
	}
}

.calendar {
	margin-top: 16px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	gap: 16px;
	overflow: auto;
}

.month {

	&Header {
		padding: 5px 0;
		border-bottom: $borderSection;
		text-align: center;
		font-weight: normal;
		color: $colorSecondary;
	}

	&Table {

		& th,
		& td {
			width: 20px;
			height: 20px;
			border-radius: 4px;
			text-align: center;
		}

		& td[data-value]:hover {
			background-color: $gray-500;
			cursor: pointer;
		}
	}
}

.currentDay {
	background-color: $gray-500;
	color: #000;
}

.holiday {
	color: $red;
	font-weight: bold;
}

.loading,
.failed {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.tagClose {
	position: absolute;
	left: -50px;
	bottom: 85px;
	padding: 4px 8px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}