@import '../../styles/variables.scss';

.container {
	position: absolute;
	width: 100vw;
	height: 100vh;
	background-position: center;
	background-size: cover;
	background-color: $backgroundColorBlandMode;
	background-blend-mode: screen;

	@media (max-width: 1024px) {
		width: 1024px;
	}
}