@import '../../styles/variables.scss';

.speaker {
	&Wrapper {
		position: absolute;
		top: -16px;
		left: 0;
		color: $colorSecondary;
		font-size: 12px;

		&RightPosition {
			left: initial;
			right: 0;
		}

		&:hover .speakerInfo {
			display: block;
		}
	}

	&Info {
		position: absolute;
		bottom: 20px;
		display: none;
		padding: 8px 16px;
		border-radius: 6px;
		box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
		background: $backgroundColor;
		color: $colorPrimary;
		white-space: nowrap;
		z-index: 10;

		&BottomPosition {
			top: 20px;
			bottom: initial;
		}

		&RightPosition {
			right: 0;
		}

		&Header {
			margin-top: 8px;
			position: relative;

			&Border:before {
				content: "";
				position: absolute;
				top: -4px;
				left: 20%;
				width: 60%;
				border-top: $borderSection;
			}
		}
	}
}

.line {
	padding: 8px;
	max-width: 90%;
	border: $borderBlockInfo;
	border-radius: 0 10px 10px 10px;
	position: relative;

	&:first-child {
		margin-top: 16px;
	}

	&Toxicity {
		background-color: $lightRed;
	}

	&Active {
		background-color: $colorSecondaryLight3;
	}

	&RightPosition {
		align-self: flex-end;
		border-radius: 10px 0 10px 10px;
	}

	&Replica {
		display: flex;
		flex-wrap: wrap;
	}

	&Word {
		padding: 1px 2px;
		display: block;
		outline: none;
		white-space: pre-wrap; // !важно
		word-wrap: break-word;
		overflow: hidden;

		&ActiveBlue {
			// font-weight: bold;
			text-shadow: 0 0 1px $colorSecondary; // lifehack (пожирнее и не смещается длина)
			color: $colorSecondary;
		}

		&ActiveRed {
			text-shadow: 0 0 1px $red; // lifehack (пожирнее и не смещается длина)
			color: $red;
		}

		&Delimiter {
			width: 100%;
			height: 5px;
		}
	}
}

.contextMenu {
	position: fixed;
	border-radius: 6px;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
	background: $backgroundColor;
	overflow: hidden;
	z-index: 30;

	&Option {
		padding: 8px 16px;
		color: $colorSecondary;
		cursor: pointer;

		&:hover {
			background-color: $colorSecondaryLight3;
		}

		&:not(:last-child) {
			border-bottom: $borderBlockInfo;
		}

		&NotActive {
			color: $colorPrimary;
			cursor: not-allowed;
		}
	}
}