@import '../../../styles/variables.scss';

.wrapper {
	flex-grow: 1;
	margin-top: 4px;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
}

.container {
	height: 100%;
	overflow: auto;
}

.table {
	min-width: 100%;
	border-spacing: 0;
}

.thead {
	position: sticky;
	top: 0;
	background-color: $backgroundColor;

	&BoxShadow {
		box-shadow: 0 5px 10px 0 $backgroundColor;
	}
}

.th {
	padding: 8px 4px;
	border-bottom: 1px solid #e0e0e0;
	border-radius: 4px 4px 0 0;
	vertical-align: top;
	font-weight: normal;
	color: $colorSecondary;
	position: relative;

	&Title {
		margin-bottom: 6px;
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 14px;
		cursor: pointer;

		&Select {
			margin-top: 33px;
			display: flex;
			justify-content: center;
		}
	}
}

.tbody {
	& .tr {
		&Selected {
			background-color: rgba(25, 118, 210, 0.08);
		}

		& .td {
			height: 18px;
			padding: 0 4px;
			border-bottom: 1px solid #e0e0e0;
			text-align: center;
		}

		&:hover {
			background-color: $backgroundColorRow;
		}
	}
}

.cellInput {
	width: 100%;
	height: 100%;
	padding: 0 2px;
	border: none;
	background: inherit;
	outline: none;
	font-size: inherit;
	font-family: inherit;
	color: $colorPrimary;
	text-overflow: ellipsis;

	&:focus {
		border: 1px solid $colorPrimary;
		border-radius: 3px;
		background-color: rgba(0, 0, 0, 0.07);
	}
}

.checkbox {
	accent-color: $colorSecondary;
}

.resizer {
	&Wrapper {
		position: absolute;
		right: -7px;
		top: 4px;
		height: 25px;
		width: 14px;
		display: flex;
		justify-content: center;
		cursor: col-resize;
		user-select: none;
		touch-action: none;
		z-index: 10;
		opacity: 0;

		&:hover {
			opacity: 1;
		}
	}

	&Delimiter {
		width: 3px;
		height: 100%;
		background: $colorPrimary;
		border-radius: 2px;
	}
}

.notFound {
	padding: 8px 0;
	text-align: center;
}