@import '../../../styles/variables.scss';

.answer {
	padding: 4px 8px 8px;
	border: $borderBlockInfo;
	border-radius: 6px;

	&:hover &TopBlockControls {
		display: flex;
		// opacity: 1;

		// @starting-style {
		// 	opacity: 0;
		// }
	}

	&:hover &TopBlockChannel {
		display: none;
	}

	&TopBlock {
		display: flex;
		justify-content: space-between;
		align-items: center;

		// &Channel {
		// 	transition: 0.2s;
		// 	transition-behavior: allow-discrete;
		// }

		&Controls {
			display: none;
			gap: 10px;
			// opacity: 0;
			// transition: 0.2s;
			// transition-behavior: allow-discrete;

			&Btn {
				display: flex;
				align-items: center;
				border: none;
				background-color: transparent;
				outline: none;
				color: $colorPrimary;
				cursor: pointer;
				transition: 0.3s;
				will-change: transform;

				&:hover {
					transform: scale(1.1);
				}
			}
		}
	}
}