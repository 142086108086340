@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.wrapper {
	position: absolute;
	top: 56px;
	right: 0;
	width: calc(100vw - 450px);
	height: calc(100vh - 56px);
	border-left: $borderSection;
	box-shadow: -1px 4px 6px $shadowColor;
	background: $backgroundColor;
	text-align: left;

	&Table {
		padding: 16px;
		height: 100%;
		background-color: $backgroundColor;
		overflow: auto;

		&ScreenLock {
			position: absolute;
			top: 16px;
			bottom: 16px;
			left: 16px;
			right: 16px;
			border-radius: 6px;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: rgba(0, 0, 0, 0.1);
			z-index: 10;
		}
	}
}

.noData {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tagClose {
	position: absolute;
	bottom: 85px;
	left: -50px;
	width: 80px;
	padding: 4px 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	box-shadow: 0 -3px 6px $backgroundColorBtn;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}