.inputsDate,
.buttonsImportCorpus {
	margin-top: 8px;
	margin-bottom: 4px;
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.noData {
	color: red;
	font-size: 13px;
}