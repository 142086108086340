$backgroundColor: #F6F6F9;
$backgroundColorBtn: #DCDCDC;
$shadowColor: #cacaca;
$backgroundColorBlandMode: #848484;
$backgroundColorLightening: rgba(255, 255, 255, 0.85);
$backgroundColorRow: rgba(0, 0, 0, 0.04);
$backgroundColorCondition: rgba(0, 0, 0, 0.1);

$colorPrimary: #918C8C;
$colorSecondary: rgb(25, 118, 210); // синий 90-100%
$colorSecondaryLight: rgb(102, 164, 225); // светлее синего 10-90%
$colorSecondaryLight2: rgb(178, 209, 240); // еще светлее 0-10%
$colorSecondaryLight3: rgb(225, 240, 255); // вообще светлый
$colorBorderHoverBar: rgb(255, 204, 0);

$gray-200: #808080;
$gray-500: #bebebe;
$light-primary: transparent;

$green: #3A9B17;
$red: #db6969;
$lightRed: rgb(251, 209, 216);

$borderSection: 1px solid #b2b2b2;
$borderBlockInfo: 1px solid #dfdfdf;
$shadowColorBlockInfo: #dfdfdf;

$linearGradientFromTopToBottom: linear-gradient(transparent 0%, #000 8px, #000 calc(100% - 8px), transparent 100%);
$linearGradientFromRightToLeft: linear-gradient(to left, #000 4px, transparent 5px, transparent 100%);