@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.documentParameters {
	position: absolute;
	top: 0;
	right: 0;
	width: 600px;
	height: 100%;
	padding: 16px;
	border-left: $borderSection;
	background: $backgroundColor;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;

	&Top {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		overflow: auto;
	}

	&Bottom {
		margin-top: 8px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&DeleteBtn {
			color: $red;
			text-decoration: underline;
			cursor: pointer;
		}

		&Right {
			display: flex;
			gap: 8px;

			&Export {
				cursor: default;
			}
		}
	}
}

.tagClose {
	position: absolute;
	right: 570px;
	bottom: 85px;
	padding: 4px 8px 5px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}