@import '../../../styles/variables.scss';

.wrapper {
	flex-grow: 1;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: auto;
}

.container {
	height: 100%;
	overflow: auto;
}

.table {
	min-width: 100%;
	border-spacing: 0;
}

.thead {
	position: sticky;
	z-index: 10;
	top: 0;
	background-color: $backgroundColor;

	&BoxShadow {
		box-shadow: 0 5px 10px 0 $backgroundColor;
	}
}

.th {
	padding: 8px 4px;
	border-bottom: 1px solid #e0e0e0;
	border-radius: 4px 4px 0 0;
	vertical-align: top;
	font-weight: normal;
	color: $colorSecondary;
	position: relative;

	&Title {
		margin-bottom: 6px;
		display: flex;
		align-items: center;
		gap: 10px;
		font-size: 14px;
		cursor: pointer;
	}
}

.tbody {

	& .tr {

		&:hover {
			background-color: $backgroundColorRow;
		}

		& .td {
			padding: 0 4px;
			height: 18px;
			max-width: 200px;
			border-bottom: 1px solid #e0e0e0;
			text-align: left;
			position: relative;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;

			&Payload {
				cursor: pointer;
			}
		}
	}
}

.popup {
	position: absolute;
	z-index: 10;
	top: 50%;
	left: 50%;
	padding: 8px 0;
	min-width: 20%;
	max-width: 60%;
	min-height: 10%;
	max-height: 80%;
	display: flex;
	flex-direction: column;
	border-radius: 4px;
	background-color: $backgroundColor;
	box-shadow: 0 0 10px $shadowColor;
	text-align: left;
	transform: translate(-50%, -50%);

	&Inner {
		flex-grow: 1;
		padding: 0 8px;
		overflow: auto;
	}

	&CloseBtn {
		position: absolute;
		z-index: 10;
		top: 10px;
		right: 10px;
		border: none;
		outline: none;
		background: transparent;
		cursor: pointer;
	}
}

.notFound {
	padding: 8px 0;
	text-align: center;
}