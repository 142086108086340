@import '../../../styles/variables.scss';

.setting {
	position: absolute;
	bottom: 48px;
	width: 100%;
	height: 16px;
	overflow: hidden;
	transition: .3s;
	z-index: 30;

	&FullSize {
		height: auto;
	}

	&ShowButton {
		width: 30px;
		height: 16px;
		border: none;
		outline: none;
		border-radius: 4px 4px 0 0;
		background-color: #f4f7f9;
		color: inherit;
		cursor: pointer;
	}

	&Block {
		padding: 8px 8px 0;
		max-height: 200px;
		display: none;
		background-color: #f4f7f9;
		overflow: auto;

		&Visible {
			display: block;
		}

		&Inner {
			display: flex;
			gap: 8px;

			&Header {
				padding-top: 8px;
				justify-content: center;
				border-top: $borderBlockInfo;
			}

			&:not(:last-child) {
				margin-bottom: 8px;
			}
		}
	}

	&Checkbox {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		gap: 10px;
		cursor: pointer;

		&>input {
			accent-color: $colorSecondary;
		}
	}
}