@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.autoMark {
	position: absolute;
	top: 0;
	right: 0;
	// min-width: 200px;
	width: calc(100vw - 400px);
	height: 100%;
	border-left: $borderSection;
	background: $backgroundColor;

	&Inner {
		padding: 16px;
		height: 100%;
		position: relative;
	}

	&Data {
		height: 100%;
		display: flex;
		gap: 16px;
	}

	&List {
		flex-shrink: 0;
		margin: 0 -16px;
		padding: 0 16px;
		min-width: 200px;
		max-width: 300px;
		font-size: 12px;
		overflow: auto;
	}

	&Item {
		padding: 2px;
		display: flex;
		border-radius: 6px;
		cursor: pointer;

		&:hover {
			background-color: $backgroundColorRow;
			color: $colorSecondary;
		}

		&Active {
			background-color: $backgroundColorBtn;
			color: $colorSecondary;

			&:hover {
				background-color: $backgroundColorBtn;
				cursor: default;
			}
		}

		&Name {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	&Result {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		gap: 16px;
		text-align: left;

		&Bottom {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 10px;
			flex-wrap: wrap;
		}
	}
}


.loading,
.failed,
.empty {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	&AutomarkResult {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 24px;
		border: $borderBlockInfo;
		box-shadow: 0 0 8px $shadowColor;
		border-radius: 4px;

		&Update {
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

.tagClose {
	position: absolute;
	left: -50px;
	bottom: 85px;
	padding: 4px 8px 5px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}