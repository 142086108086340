@import '../../../styles/variables.scss';

.container {
	padding: 8px 16px;
	border: $borderSection;
	border-radius: 4px;
	background: $backgroundColorRow;

	&Opacity {
		opacity: 0.5;
	}
}

.action {
	display: flex;
	align-items: center;
	gap: 12px;
}