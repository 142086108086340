@import '../../styles/variables.scss';

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	z-index: 30;
}

.userLogs {
	position: absolute;
	top: 0;
	right: 0;
	padding: 16px;
	min-width: calc(100% - 340px);
	max-width: 90%;
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-left: $borderSection;
	background: $backgroundColor;

	&SetInterval {
		display: flex;
		flex-wrap: wrap;
		gap: 12px;
	}

	&Block {
		flex-grow: 1;
		display: flex;
		gap: 12px;
	}
}

.loading,
.failed,
.empty {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
}

.tagClose {
	position: absolute;
	left: -50px;
	bottom: 85px;
	padding: 4px 8px;
	width: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: $borderSection;
	border-bottom: none;
	border-radius: 4px 4px 0 0;
	background-color: $backgroundColor;
	transform: rotate(-90deg);
	transition: 0.3s;
	cursor: pointer;
}