@import '../../styles/variables.scss';

.manualCheck {
	position: absolute;
	top: 56px;
	right: 0;
	min-width: 360px;
	width: 360px;
	height: calc(100vh - 56px);
	padding: 16px;
	border-left: $borderSection;
	box-shadow: -1px 4px 6px $shadowColor;
	display: flex;
	flex-direction: column;
	background: $backgroundColor;
	text-align: left;
	overflow-x: hidden;
	overflow-y: auto;

	&Top {
		height: 150px;
		display: flex;
		flex-direction: column;
	}

	&Textarea {
		flex-grow: 1;
		margin-bottom: 4px;
		width: 100%;
		padding: 10px;
		border: $borderSection;
		border-radius: 4px;
		outline: none;
		resize: none;
		background-color: inherit;
		color: inherit;
		font-size: inherit;
		font-family: inherit;

		&:hover {
			border: 1px solid #000;
		}

		&:focus {
			padding: 9px;
			border: 2px solid $colorSecondary;
		}

		&::placeholder {
			opacity: 0.5;
		}
	}

	&Data {

		&>div,
		&>p {
			margin-top: 8px;
			padding-top: 8px;
			border-top: $borderSection;
		}

		&Pair {
			word-break: break-word;

			&Block {
				margin-top: 5px;
			}

			&Inner {
				margin-left: 10px;

				&Key {
					color: $colorSecondaryLight;
				}
			}
		}

		&Key {
			font-weight: bold;
		}
	}
}

.manualCheckWithErrors {
	position: static;
	height: 100%;
	border-top: none;
	border-left: none;
	border-radius: 4px;
	box-shadow: 0 0 8px $shadowColor;

	@media (max-width: 950px) {
		width: 100%;
		min-height: 50%;
	}
}

.failedText,
.notFoundText,
.loading,
.internalError {
	text-align: center;
}

.failedText {
	margin-bottom: 8px;
	font-size: 13px;
	text-overflow: ellipsis;
	overflow: hidden;
}

.loading {
	margin-bottom: 7px;
}